import { Badge, Box, Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react'
import React from 'react'
import SettingsCard from './SettingsCard.js'
import { useLibrary } from '../../hooks/useData.js'

const LibrarySharing = () => {
  const library = useLibrary()
  const stylesheetShared = library.settings.templates?.includes('stylesheets')
  const collectionsShared = library.settings.templates?.includes('collections')
  return (
    <SettingsCard
      name={'Library sharing'}
      description={
        'Share your library styles and collections with anyone in your organization. The libraries you share are always up-to-date with your latest edits, while users get a copy of the asset when they access it.'
      }
      top={
        <Box>
          {collectionsShared && (
            <Badge colorScheme={'teal'} mr='2' px={2} fontSize={'md'}>
              Sharing collections
            </Badge>
          )}
          {stylesheetShared && (
            <Badge colorScheme={'teal'} mr='2' px={2} fontSize={'md'}>
              Sharing styles
            </Badge>
          )}
        </Box>
      }
    >
      <Box mt={4} mb={8}>
        <FormControl display='flex' alignItems='center' width='auto' mt={2}>
          <Switch
            id={'share-current-lib-stylesheet-toggle'}
            isChecked={stylesheetShared}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (stylesheetShared) {
                const templates = library.settings.templates.filter((t) => t !== 'stylesheets')
                library.save({ settings: { ...library.settings, templates } })
              } else {
                const templates = [...(library.settings.templates || []), 'stylesheets']
                library.save({ settings: { ...library.settings, templates } })
              }
            }}
            size='md'
          />
          <FormLabel htmlFor='share-current-lib-stylesheet-toggle' mb='0' ml={4}>
            <Text>Share style library within your organization</Text>
          </FormLabel>
        </FormControl>
      </Box>
      <Box mt={8} mb={4}>
        <FormControl display='flex' alignItems='center' width='auto' mt={2}>
          <Switch
            id={'share-current-lib-collections-toggle'}
            isChecked={collectionsShared}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (collectionsShared) {
                const templates = library.settings.templates.filter((t) => t !== 'collections')
                library.save({ settings: { ...library.settings, templates } })
              } else {
                const templates = [...(library.settings.templates || []), 'collections']
                library.save({ settings: { ...library.settings, templates } })
              }
            }}
            size='md'
          />
          <FormLabel htmlFor='share-current-lib-collections-toggle' mb='0' ml={4}>
            <Text>Share all component collections within your organization</Text>
          </FormLabel>
        </FormControl>
      </Box>
    </SettingsCard>
  )
}

export default LibrarySharing

import { Box, Button, Container, Flex, HStack, Icon, Text } from '@chakra-ui/react'
import { mdiAlertCircle } from '@mdi/js'
import { useLibrary } from '../../hooks/useData.js'
import { isDeepEquals } from '@sitecore-feaas/sdk'
import { useNavigate } from 'react-router'

const StylesFooter = () => {
  const navigate = useNavigate()
  const library = useLibrary()
  const stylesheet = useLibrary('stylesheets.first')
  const stagedUpToDate = stylesheet.isUpToDateWith('staged')
  const publishedUpToDate = stylesheet.isUpToDateWith('published')
  return (
    // TODO: Is there a way to avoid z-index (when editing card is open in style guides it stays behind)?
    <>
      <Box
        borderTop={'1px solid rgba(0, 0, 0, 0.1)'}
        position={'fixed'}
        bottom={0}
        width={'100%'}
        zIndex={1}
        id='fixed-footer'
      >
        {(!stagedUpToDate || !publishedUpToDate) && (
          <Box backgroundColor={'primary.100'} height={'100%'}>
            <Container maxW='container.xl' pl={10} pr={10} py={4}>
              <Flex justifyContent={'space-between'}>
                <HStack>
                  <Icon boxSize={'icon-xl'}>
                    <path color={'#5548D9'} d={mdiAlertCircle} />
                  </Icon>
                  <Text alignSelf={'center'}>
                    {publishedUpToDate && !stagedUpToDate
                      ? 'You made changes to the style library. To update styles in Pages you need to stage them.'
                      : !stagedUpToDate
                      ? 'You made changes to the style library. To update styles in Pages or in production and the live website you need to stage or publish them.'
                      : 'You made changes to the style library. To update styles in production and the live website you need to publish them.'}
                  </Text>
                </HStack>
                <Button
                  variant={'primary'}
                  backgroundColor={'blackAlpha.500'}
                  onClick={() =>
                    navigate(library.getPath() + `/publishing/styles/${!stagedUpToDate ? 'staged' : 'published'}`)
                  }
                >
                  Go to style publishing
                </Button>
              </Flex>
            </Container>
          </Box>
        )}
      </Box>
    </>
  )
}

export default StylesFooter

import React, { useState } from 'react'
import { Style } from '@sitecore-feaas/sdk'
import Preview from '../previews/index.js'
import { Badge, Flex, Grid, GridItem, VStack, Checkbox, IconButton, Icon, Box } from '@chakra-ui/react'
import { Title } from '../../styled.js'
import StyleFontSummary from './StyleFontSummary.js'
import { mdiMinusCircleOutline, mdiPencilOutline } from '@mdi/js'

const StyleSelectGrid = ({
  options,
  rules,
  onCheck,
  isChecked,
  isDisabled,
  isDefault,
  isBlank = false,
  onMakeDefault,
  onCustomRuleEdit,
  editSelection = false
}: {
  options: Style.Rule[]
  rules: Style.Rule[]
  onCheck: (id: Style.RuleId) => void
  isChecked: (id: Style.RuleId) => boolean
  isDisabled?: (id: Style.RuleId) => boolean
  isDefault?: (id: Style.RuleId) => boolean
  isBlank?: boolean
  onMakeDefault?: (id: Style.RuleId) => void
  onCustomRuleEdit?: () => void
  editSelection?: boolean
}) => {
  const [makeDefault, setMakeDefault] = useState(null)

  return (
    <Grid templateColumns='repeat(auto-fill, minmax(220px, 1fr))' gap={3}>
      {options.map((style: Style.Rule) => (
        <GridItem
          key={style.details.id}
          onMouseEnter={() => setMakeDefault(style.details.id)}
          onMouseLeave={() => setMakeDefault(null)}
          px={2}
          pt={2}
          pb={1}
          layerStyle='outline'
          pos='relative'
          cursor={editSelection && 'pointer'}
          onClick={() => editSelection && onCheck(style.details.id)}
        >
          <Preview rule={style} rules={rules} />

          {!editSelection && (
            <Badge
              variant={!isDefault(style.details.id) ? 'clickable' : 'subtle'}
              opacity={!isDefault(style.details.id) && makeDefault !== style.details.id ? 0 : 1}
              selected={isDefault(style.details.id)}
              pos='absolute'
              fontSize='sm'
              pointerEvents={isDefault(style.details.id) ? 'none' : null}
              top={4}
              left={4}
              size='sm'
              colorScheme='purple'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onMakeDefault(style.details.id)
              }}
            >
              {isDefault(style.details.id) ? 'Default' : 'Make default'}
            </Badge>
          )}

          <Flex mt={1} alignItems='start' justifyContent='space-between'>
            <VStack spacing={-1} alignItems='start' maxW='full'>
              <Flex {...Title} h={8} alignItems='center'>
                {style.details.title}
              </Flex>
              {!isBlank && <StyleFontSummary rule={style} rules={rules} />}
            </VStack>
            <Box position='absolute' right={2}>
              {!editSelection && onCustomRuleEdit && (
                <IconButton
                  aria-label={`Edit ${style.details.title}`}
                  variant='minimal'
                  colorScheme='blackAlpha.600'
                  size='sm'
                  icon={
                    <Icon boxSize='icon-xl'>
                      <path d={mdiPencilOutline}></path>
                    </Icon>
                  }
                  onClick={onCustomRuleEdit}
                />
              )}
              {editSelection ? (
                <Checkbox
                  mt={2}
                  value={style.details.id}
                  onChange={() => onCheck(style.details.id)}
                  isChecked={isChecked(style.details.id)}
                  isDisabled={isDisabled && isDisabled(style.details.id)}
                />
              ) : (
                <IconButton
                  aria-label={`Remove ${style.details.title}`}
                  variant='minimal'
                  colorScheme='blackAlpha.600'
                  size='sm'
                  icon={
                    <Icon boxSize='icon-xl'>
                      <path d={mdiMinusCircleOutline}></path>
                    </Icon>
                  }
                  onClick={() => onCheck(style.details.id)}
                />
              )}
            </Box>
          </Flex>
        </GridItem>
      ))}
    </Grid>
  )
}

export default StyleSelectGrid

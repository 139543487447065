import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useData, useLibrary } from '../../hooks/useData.js'
import { getIconByStyleType, sidebarCollections, sidebarStyles, StylePage } from './sidebar.js'
import {
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionPanelProps,
  Box,
  BoxProps,
  Button,
  Icon,
  Stack,
  StackProps
} from '@chakra-ui/react'

const Wrapper: BoxProps = {
  background: 'gray.50',
  height: 'full',
  width: '64',
  flexShrink: 0
}

const WrapperInner: BoxProps = {
  position: 'fixed',
  overflowY: 'auto',
  width: '64',
  paddingTop: '5',
  background: 'gray.50',
  paddingBottom: '5',
  top: '58px',
  bottom: 0
}

const SidebarAccordionItem: AccordionItemProps = {
  border: 'none'
}

const SidebarPanel: AccordionPanelProps = {
  // @ts-ignore
  paddingLeft: '2',
  // @ts-ignore
  paddingRight: '2'
}

const SidebarGroup: AccordionButtonProps = {
  fontSize: '2xs',
  color: 'gray.500',
  fontWeight: 'semibold',
  paddingTop: '3',
  paddingBottom: '3',
  _focus: {
    boxShadow: 'none'
  },
  _hover: {
    background: 'none'
  }
}

const SidebarPanelInner: StackProps = {
  paddingLeft: 0,
  paddingRight: 0
}

const StylesSidebar = ({
  activeStyleType,
  setActiveStylePage
}: {
  activeStyleType: string
  setActiveStylePage: (stylePage: StylePage) => void
}) => {
  const library = useLibrary()
  const navigate = useNavigate()

  const remembered =
    localStorage.getItem('feaas:styles:collapsed') && JSON.parse(localStorage.getItem('feaas:styles:collapsed'))

  const [collapsed, setCollapsed] = useState<any>(remembered || sidebarCollections.map((collection, index) => index))

  useEffect(() => {
    localStorage.setItem('feaas:styles:collapsed', JSON.stringify(collapsed))
  }, [collapsed])

  const openStyle = (style: StylePage) => navigate(`${library.getPath()}/styles/${style.collectionId}/${style.type}`)

  const stylePage = sidebarStyles.find((item) => item.type === activeStyleType)

  const getGroupNavigationItems = (groupId: string) => sidebarStyles.filter((style) => style.collectionId === groupId)

  const isActive = (type: string) => activeStyleType === type

  useEffect(() => {
    setActiveStylePage(stylePage)
  }, [stylePage])

  return (
    <Box {...Wrapper} className='sidebar'>
      <Box {...WrapperInner} pb={16}>
        <Accordion allowMultiple index={collapsed} onChange={setCollapsed} reduceMotion={true}>
          {sidebarCollections.map((group) => (
            <AccordionItem {...SidebarAccordionItem} key={group.id}>
              <AccordionButton {...SidebarGroup} key='button'>
                <>
                  <Box>{group.title.toUpperCase()}</Box>
                  <AccordionIcon ml='auto' />
                </>
              </AccordionButton>

              <AccordionPanel {...SidebarPanel} key='panel'>
                <Stack {...SidebarPanelInner}>
                  {getGroupNavigationItems(group.id).map((navigationItem) => (
                    <Button
                      key={navigationItem.type}
                      onClick={() => openStyle(navigationItem)}
                      isDisabled={navigationItem.disabled}
                      variant={isActive(navigationItem.type) ? 'navVerticalActive' : 'navVertical'}
                      leftIcon={
                        <Icon boxSize='6'>
                          <path d={getIconByStyleType(navigationItem.type)} />
                        </Icon>
                      }
                    >
                      {navigationItem.title}
                    </Button>
                  ))}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Box>
  )
}

export default StylesSidebar

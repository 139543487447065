import { Box, Button, Container, Flex, Spacer, Text } from '@chakra-ui/react'
import { useData, useLibrary, useSDK } from '../hooks/useData.js'
import { useParams } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageAction, PageContainer, PageDescription, PageTitle } from '../components/styled.js'
import EntitiesProvider from '../components/entities/EntitiesProvider.js'
import useDocumentTitle from '../hooks/useDocumentTitle.js'
import Datasource from '../components/datasources/Datasource.js'
import EmptyContent from '../components/EmptyContent.js'

export default function Datasources() {
  const internalDatasources = useLibrary('datasources')
  const datasources = useSDK('datasources')
  const library = useLibrary()
  const { datasourceId } = useParams()
  const navigate = useNavigate()
  const components = useLibrary('components')
  useDocumentTitle('Data sources')

  const onCreateDatasource = () => {
    const { id } = internalDatasources.add({ name: '', description: '', sampledAt: null, sample: null })
    navigate(`${library.getPath()}/datasources/${id}`)
  }

  return (
    <Container {...PageContainer} id='overflow-wrapper'>
      <Flex {...PageDescription}>
        <Text {...PageTitle}>Data sources</Text>

        <Spacer />

        <Button {...PageAction} variant='secondary' size='md' onClick={onCreateDatasource}>
          Add data source
        </Button>
      </Flex>
      <EntitiesProvider
        url={`${library.getPath()}/datasources`}
        activeEntityId={datasourceId}
        ids={datasources.map(({ id }) => id)}
      >
        {datasources.length > 0 ? (
          <Box mt='10'>
            {datasources.map((datasource) => (
              <Datasource
                datasource={datasource}
                key={datasource.id}
                componentCount={
                  components.filter((component) => component.datasourceIds.includes(datasource.id)).length
                }
              />
            ))}
          </Box>
        ) : (
          <EmptyContent
            title={'You dont have any data source'}
            subtitle={
              <Button variant={'primary'} onClick={onCreateDatasource}>
                Add datasource
              </Button>
            }
          />
        )}
      </EntitiesProvider>
    </Container>
  )
}

import { Option } from '../types/index.js'
import { useContext, useMemo } from 'react'
import { QueryStringContext } from '../components/providers/QueryStringProvider.js'
import { groupBy as group } from 'lodash-es'

const useFilter = <T extends { id: string; name: string }>(
  pool: T[],
  queryParam: string,
  groupBy?: string,
  labelTransformers?: any
): [Option[], Option[], (selectedOptions: Option[]) => void, string[], boolean] => {
  const [query, setQuery] = useContext(QueryStringContext)

  const options: any = groupBy
    ? Object.keys(group(pool, groupBy)).map((key) => {
        return {
          label: labelTransformers[key] || key,
          options: group(pool, groupBy)[key].map(({ id, name }) => ({
            label: labelTransformers[name] || name,
            value: id
          }))
        }
      })
    : pool.map(({ name, id }) => ({
        label: name,
        value: id
      }))

  const selectedOptions: Option[] = []
    .concat(query[queryParam])
    .filter(Boolean)
    .map((id) => {
      const thing = pool.find((d) => d.id === id)
      if (!thing) return null
      return { label: thing.name, value: thing.id }
    })
    .filter(Boolean)

  const onOptionsChange = (selectedOptions: Option[]): void =>
    setQuery({ [queryParam]: selectedOptions.map(({ value }) => value) })

  const filteredIds = useMemo(() => {
    const isFilteringEnabled = selectedOptions.length !== 0
    const allIds = pool.map(({ id }) => id)
    const selectedIds = selectedOptions.map(({ value }) => value)
    return isFilteringEnabled ? selectedIds : allIds
  }, [selectedOptions])

  return [options, selectedOptions, onOptionsChange, filteredIds, !!query[queryParam]]
}

export default useFilter

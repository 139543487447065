import useDocumentTitle from '../../hooks/useDocumentTitle.js'
import { PageContainer } from '../styled.js'
import {
  Box,
  Container,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
  FormLabel,
  FormHelperText,
  FormControl
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Select } from '../Select.js'
import { useLibrary } from '../../hooks/useData.js'
import FieldsetField from '../FieldsetField.js'
import { ComponentModel, StylesheetModel } from '@sitecore-feaas/sdk'
import PublisherStylesComponent from './PublisherStylesComponent.js'
import PublisherStylesFooter from './PublisherStylesFooter.js'
import { mdiCheckCircle } from '@mdi/js'

export const publisherStylesTexts = {
  draft: {
    label: 'Draft',
    title: 'Draft styles',
    description: 'These styles are only visible in XM Cloud Components',
    action: () => 'Revert',
    footerText: (comparedStatus: string) => `Reset changes in draft and revert to ${comparedStatus} state`
  },
  saved: {
    label: 'Draft',
    title: 'Draft styles',
    description: 'These styles are only visible in XM Cloud Components',
    action: () => 'Revert',
    footerText: (comparedStatus: string) => `Reset changes in draft and revert to ${comparedStatus} state`
  },
  staged: {
    label: 'Staged',
    title: 'Staged styles in Pages',
    description: 'Staged styles are visible in Pages but are not saved to production and not visible on the live site',
    action: (comparedStatus: string) => (comparedStatus === 'published' ? 'Revert' : 'Stage'),
    footerText: (comparedStatus: string) =>
      comparedStatus === 'published'
        ? 'Reset staged Pages styles to previously published state'
        : 'Stage drafted style changes to Pages'
  },
  published: {
    label: 'Published',
    title: 'Published styles in production',
    description: 'Published styles are saved on production and visible on the live site',
    action: () => 'Publish',
    footerText: (comparedStatus: string) => `Publish ${comparedStatus} changes to production`
  }
}
const PublisherStyles = ({ targetStatus }: { targetStatus: StylesheetModel['status'] }) => {
  const components = useLibrary('components')
  const stylesheet = useLibrary('stylesheets.first')

  const [comparedStylesheet, setComparedStylesheet] = useState<StylesheetModel>(() =>
    stylesheet.getComparedStylesheet(targetStatus)
  )
  const [loadingVersions, setLoadingVersions] = useState(false)

  const fetchComponentVersions = (component: ComponentModel) => {
    if (component && component.versions.length === 0) {
      setLoadingVersions(true)
      component?.versions.fetch().then((r) => setLoadingVersions(false))
    }
  }

  const [component, setComponent] = useState<ComponentModel>(() => {
    if (comparedStylesheet && components.length) {
      fetchComponentVersions(components[0])
      return components[0]
    } else {
      return null
    }
  })
  const currentStylesheet = stylesheet.getCurrent(targetStatus)
  const allUpToDate = stylesheet.isUpToDateWith('staged') && stylesheet.isUpToDateWith('published')

  const formatStylesheetLabel = (stylesheet: StylesheetModel) =>
    targetStatus === 'saved'
      ? `Previously ${publisherStylesTexts[stylesheet.status].label.toLowerCase()}`
      : publisherStylesTexts[stylesheet.status].label

  const targetDropdownOptions: StylesheetModel[] = [
    stylesheet.getCurrent('saved') || [],
    stylesheet.getCurrent('staged') || [],
    stylesheet.getCurrent('published') || []
  ]
    .flat()
    .filter((stylesheet) => stylesheet.status !== targetStatus && !stylesheet.isUpToDateWith(targetStatus))

  const targetDropdownOptionsAreEqual =
    targetDropdownOptions.length &&
    targetDropdownOptions.every((s) => s.isUpToDateWith(targetDropdownOptions[0].status))

  const setComponentAndFetchVersions = (component: ComponentModel) => {
    setComponent(component)
    fetchComponentVersions(component)
  }

  useDocumentTitle('Styles publishing')
  return (
    <>
      <Box height={'full'} flex={1}>
        <Container {...PageContainer}>
          <VStack alignItems={'left'} spacing={3}>
            <VStack alignItems={'left'}>
              <Text as='h2' fontSize='4xl' fontWeight='semibold'>
                {publisherStylesTexts[targetStatus].title}
              </Text>
              <Text>{publisherStylesTexts[targetStatus].description}</Text>
            </VStack>
            <Spacer />
            {allUpToDate ? (
              <Flex alignSelf={'center'}>
                <VStack>
                  <Icon boxSize={'50px'}>
                    <path color={'#00a182'} d={mdiCheckCircle} />
                  </Icon>
                  <Text maxW={270} textAlign={'center'} fontWeight={600}>
                    Draft, staged and published styles are up-to-date
                  </Text>
                </VStack>
              </Flex>
            ) : (
              comparedStylesheet && (
                <>
                  <HStack>
                    <Text>Compare {publisherStylesTexts[targetStatus].title.toLowerCase()} to</Text>
                    <Box display={'inline-block'}>
                      {targetDropdownOptions.length > 1 && !targetDropdownOptionsAreEqual ? (
                        <FieldsetField extraProps={{ flex: 1, minW: '60' }}>
                          <Select
                            options={targetDropdownOptions}
                            onChange={(selected) => setComparedStylesheet(stylesheet.getCurrent(selected.status))}
                            value={comparedStylesheet}
                            getOptionLabel={formatStylesheetLabel}
                            getOptionValue={(s) => s.status}
                          />
                        </FieldsetField>
                      ) : (
                        <Text fontWeight={600}>
                          {targetDropdownOptionsAreEqual
                            ? targetDropdownOptions.map((s) => formatStylesheetLabel(s)).join(' and ')
                            : formatStylesheetLabel(comparedStylesheet)}
                        </Text>
                      )}
                    </Box>
                  </HStack>
                  <>
                    <Spacer />
                    <HStack>
                      <VStack alignItems={'flex-start'}>
                        <FormControl>
                          <FormLabel>
                            <Text>Select component to preview style changes</Text>
                          </FormLabel>
                          <Box display={'inline-block'}>
                            <FieldsetField extraProps={{ flex: 1, minW: '60' }}>
                              <Select
                                options={components}
                                onChange={setComponentAndFetchVersions}
                                value={component}
                                getOptionLabel={(c) => c.name}
                                getOptionValue={(c) => c.id}
                              />
                            </FieldsetField>
                          </Box>
                          <FormHelperText>These versions might not cover all style changes</FormHelperText>
                        </FormControl>
                      </VStack>
                    </HStack>
                    <Box>
                      {component && (
                        <PublisherStylesComponent
                          component={component}
                          loading={loadingVersions}
                          currentStylesheet={currentStylesheet}
                          comparedStylesheet={comparedStylesheet}
                        />
                      )}
                    </Box>
                  </>
                </>
              )
            )}
          </VStack>
        </Container>
      </Box>
      {!allUpToDate && comparedStylesheet && (
        <PublisherStylesFooter
          targetStatus={targetStatus}
          comparedStylesheet={comparedStylesheet}
          onAction={() => {
            setComparedStylesheet(stylesheet.getComparedStylesheet(targetStatus))
          }}
        />
      )}
    </>
  )
}

export default PublisherStyles

import { useContext, useState } from 'react'
import { EditorContext } from '../../contexts/EditorContext.js'
import { useEditorBatch, useEditorContextStyles, useEditorStyles } from '../../hooks/useEditorStyles.js'
import Picker from '../picker/Picker.js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { CollectionModel, Style, StylesheetModel, getEmbedDefinition } from '@sitecore-feaas/sdk'
import { QueryStringContext } from '../providers/QueryStringProvider.js'
import { ComponentModel } from '@sitecore-feaas/sdk'
import * as BYOC from '@sitecore/byoc'
import { useLibrary, useSDK } from '../../exports.js'
import { EnvironmentContext } from '../providers/EnvironmentProvider.js'

export function EditorPicker({
  component,
  stylesheet,
  collections
}: {
  component: ComponentModel
  stylesheet: StylesheetModel
  collections: CollectionModel[]
}) {
  const [{ editor, sidebarMode, context, activeVersionId, isFocused, sidebarDialog }, setEditorContext] =
    useContext(EditorContext)
  const env = useContext(EnvironmentContext)
  const editorModelElement = context as CK.ModelElement
  const editorContext =
    isFocused && editorModelElement && editorModelElement.root.rootName != '$graveyard' ? editorModelElement : null
  const [onBatch] = useEditorBatch(editor, editorModelElement)
  const customStyles = useEditorContextStyles(editor, editorModelElement)
  const [query] = useContext(QueryStringContext)
  const version = component?.versions.find((v) => v.id == activeVersionId)
  const [renders, setRenders] = useState(() => 0)
  const components = useLibrary('components')
  const expandedComponents = useSDK('renderingHost.expandedComponents')
  const datasources = useSDK('datasources')

  // elements scan for the first variables and manage the text for them
  var contextVariable = editorContext
  var contextLink = editorContext
  if (editorContext) {
    if (!editorContext.is('element', 'var')) {
      for (var c of editorContext.getChildren()) {
        if (c.is('element') && c.name == 'var') {
          contextVariable = c
          break
        }
      }
    }
    // find parent link context
    for (var p of editorContext.getAncestors()) {
      if (p.is('element') && (p.getAttribute('linkHref') != null || p.getAttribute('data-path-href'))) {
        contextLink = p
      }
    }
    if (editorContext?.is('element', 'component')) {
      var embedDefinition = getEmbedDefinition(editorContext)
      var isUnconfigured = embedDefinition && !embedDefinition.getConfiguration(editorContext)
      var externalComponent =
        embedDefinition.type == 'feaas'
          ? components.find((c) => c.id == editorContext.getAttribute('component'))
          : BYOC.getComponent(String(editorContext.getAttribute('data-external-id')))
    }
  }

  return (
    <Picker
      cdn={env.cdn}
      component={component}
      embed={externalComponent}
      externalComponents={expandedComponents}
      mode={
        (editorContext && sidebarMode) ||
        (sidebarMode === 'bundles' && sidebarMode) ||
        (isUnconfigured ? 'library' : editorContext ? 'context' : 'overview')
      }
      rules={stylesheet.rules}
      collections={collections}
      datasources={datasources}
      onModeChange={(sidebarMode, sidebarDialog) => setEditorContext({ sidebarMode, sidebarDialog })}
      dialog={sidebarDialog}
      onSelect={(context) => editor.execute('setContext', context)}
      customRules={customStyles}
      context={editorContext}
      data={editorContext?.getAttribute('data') as any}
      contextLink={contextLink}
      contextVariable={contextVariable}
      setLocalRule={(context, style) => {
        onBatch((writer) => {
          editor.styles.setContextStyle(context as CK.ModelElement, style)
        })
        setRenders((r) => r + 1)
      }}
      themeContext={editor?.getThemeContext(editorContext as CK.ModelElement)}
      getAttribute={(context, name) => {
        return editor.utils.getCustomAttribute(context as CK.ModelElement, name)
      }}
      setAttribute={(context, name, value) => {
        onBatch(() => {
          if (sidebarMode == 'themes' && !('setAttribute' in context)) {
            if (name == 'class') {
              version.setClassList(Style.ClassList.fromClassName(value as string))
              editor.model.enqueueChange({ isUndoable: false }, (writer) => {
                const root = editor.model.document.getRoot(version.id)
                writer.setAttribute('class', value, root)
              })
            }
          } else if (editor.isModelElement(context)) {
            editor.execute('override', name, value, context)
          }
        })
        if (name == 'data-path-href' || name == 'linkHref') {
          // workaround for case when variable becomes wrapped in a link
          editor.execute('setContext', context)
          if (contextVariable.name == 'var') {
            editor.previewPlugin.updateDOM()
          }
        }
        setRenders((r) => r + 1)
      }}
      versionId={editorContext?.getAttribute('version') && String(editorContext?.getAttribute('version') || null)}
      variant={query.picker || 'internal'}
      background={query.picker == 'pages' ? 'gray.50' : 'white'}
      id='sidebar'
      minWidth='300px'
      height='100%'
      width='300px'
      maxWidth={'300px'}
      boxShadow='xl'
      flexGrow={0}
    />
  )
}

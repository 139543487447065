import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Switch,
  Text
} from '@chakra-ui/react'
import { useLibrary, useSDK } from '../../hooks/useData.js'
import React, { useMemo } from 'react'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import ComponentVersionThumbnail from '../ComponentVersionThumbnail.js'
import SettingsCard from './SettingsCard.js'
import VersionBreakpointBadge from '../editor/version/VersionBreakpointBadge.js'

const ComponentsCollections = () => {
  const targetLibrary = useLibrary()
  const targetCollections = useLibrary('collections')
  const templateLibraries = useSDK('templateLibraries')
  const templateCollections = useMemo(() => {
    return (
      templateLibraries
        .filter((lib) => {
          const { templates } = lib.settings as { templates: string[] }
          return templates.includes('collections')
        })
        // do not include default collections
        .flatMap((lib) => lib.collections.filter((col) => col.id !== 'default'))
    )
  }, [templateLibraries])
  return (
    <SettingsCard name={'Shared components'} description={'Import out-of-the-box or shared components collections'}>
      <Grid
        display='grid'
        gap='24px'
        gridTemplateColumns={'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'}
        sx={{
          '@media (max-width: 1100px)': {
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
          }
        }}
        mt='5'
      >
        {templateCollections.map((collection) => {
          const componentForThumbnail = collection.components.length ? collection.components[0] : null
          const existsInLibrary = targetCollections.find((tc) => tc.id === collection.id)
          if (componentForThumbnail)
            return (
              <GridItem colSpan={1} key={componentForThumbnail.id}>
                <Box
                  flexBasis='320px'
                  flexShrink={1}
                  borderRadius='base'
                  boxShadow='base'
                  bg='white'
                  p={6}
                  key={collection.id}
                >
                  {componentForThumbnail && (
                    <Box slot='preview'>
                      <ComponentVersionThumbnailWrapper
                        padding={0}
                        border={'1px solid blackAlpha.300'}
                        height={280}
                        pointerEvents={null}
                        cursor={'default'}
                      >
                        <ComponentVersionThumbnail targetHeight={280} component={componentForThumbnail} />
                      </ComponentVersionThumbnailWrapper>
                    </Box>
                  )}
                  <Box slot='details' minWidth='1px' flexGrow={1} mt={3}>
                    <HStack alignItems={'center'} height={'20px'}>
                      <Text
                        fontWeight={'semibold'}
                        maxWidth={'320px'}
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        flexShrink={0.1}
                        flexBasis={'max-content'}
                      >
                        {collection.name}
                      </Text>
                      <Divider orientation='vertical' />
                      <Text
                        fontWeight={'normal'}
                        marginLeft={'2'}
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        flexShrink={1000}
                        flexGrow={1}
                        flexBasis={'50%'}
                      >
                        {collection.components.length} component{collection.components.length !== 1 ? 's' : ''}
                      </Text>
                      <Button
                        variant={'primary'}
                        size={'sm'}
                        isDisabled={!!existsInLibrary}
                        onClick={() => {
                          collection.copyToLibrary(targetLibrary)
                        }}
                      >
                        {existsInLibrary ? 'Imported' : 'Import collection'}
                      </Button>
                    </HStack>
                    {collection.library.organizationId && (
                      <Badge colorScheme={'teal'} mt={3} mr='2' px={2} fontSize={'md'}>
                        Shared collection
                      </Badge>
                    )}
                  </Box>
                </Box>
              </GridItem>
            )
          else return null
        })}
      </Grid>
    </SettingsCard>
  )
}

export default ComponentsCollections

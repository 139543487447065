import { Box, Button, Container, Flex, HStack, Icon, Spinner, Text, useToast } from '@chakra-ui/react'
import { mdiAlertCircle, mdiCloudUploadOutline } from '@mdi/js'
import { publisherStylesTexts } from './PublisherStyles.js'
import type { StylesheetModel } from '@sitecore-feaas/sdk'
import { titleize } from 'inflection'
import { useLibrary } from '../../hooks/useData.js'
import { useContext, useState } from 'react'
import { ConfirmationContext } from '../providers/ConfirmationProvider.js'

const PublisherStylesFooter = ({
  targetStatus,
  comparedStylesheet,
  onAction
}: {
  targetStatus: StylesheetModel['status']
  comparedStylesheet: StylesheetModel
  onAction: () => void
}) => {
  const toast = useToast()
  const stylesheet = useLibrary('stylesheets.first')
  const [isPublishing, setIsPublishing] = useState(false)
  const { setConfirm } = useContext(ConfirmationContext)

  const onSuccess = (action: string) => {
    onAction()
    requestAnimationFrame(() => {
      toast({
        duration: 4000,
        status: 'success',
        title: `Styles ${action}${action.charAt(action.length - 1) === 'e' ? 'd' : 'ed'} successfully`
      })
    })
  }

  const onError = (e: Error, action: string) =>
    requestAnimationFrame(() => {
      toast({
        duration: 4000,
        status: 'error',
        title: `There was an error trying to ${action} styles`,
        description: e.message
      })
    })

  const publish = async () => {
    setIsPublishing(true)
    let action: string
    try {
      switch (targetStatus) {
        case 'saved':
          action = 'revert'
          setConfirm({
            action: () => {
              comparedStylesheet.revertDraftTo().then(() => onSuccess(action))
            },
            title: 'Reverting style',
            button: 'Revert styles',
            variant: 'danger',
            body: 'Reverting styles will discard all changes made since the time of last publication. Are you sure?'
          })
          break
        case 'staged':
          if (comparedStylesheet.status === 'published') {
            action = 'revert'
            setConfirm({
              action: () => {
                comparedStylesheet.stage().then(() => onSuccess(action))
              },
              title: 'Reverting style',
              button: 'Revert styles',
              variant: 'danger',
              body: 'Reverting styles will discard all changes made since the time of last publication. Are you sure?'
            })
          } else {
            action = 'stage'
            comparedStylesheet.stage().then(() => onSuccess(action))
          }
          break
        case 'published':
          action = 'publish'
          comparedStylesheet.publish().then(() => onSuccess(action))
          break
      }
    } catch (e) {
      onError(e, action)
    }
    setIsPublishing(false)
  }

  return (
    <Box borderTop={'1px solid rgba(0, 0, 0, 0.1)'} position={'fixed'} bottom={0} width={'100%'} className={'footer'}>
      <Box backgroundColor={'gray.700'} color={'white'} height={16}>
        <Container maxW='container.xl' pl={10} pr={10} py={4}>
          <Flex justifyContent={'space-between'}>
            <Text alignSelf={'center'}>
              {comparedStylesheet && <>{publisherStylesTexts[targetStatus].footerText(comparedStylesheet.status)}</>}
            </Text>

            <Button
              width='102px'
              justifyContent={'center'}
              isDisabled={!comparedStylesheet}
              onClick={publish}
              variant={
                publisherStylesTexts[targetStatus].action(comparedStylesheet.status) === 'Revert' ? 'danger' : 'primary'
              }
              backgroundColor={
                publisherStylesTexts[targetStatus].action(comparedStylesheet.status) === 'Revert' ? 'red.600' : null
              }
              size='sm'
            >
              {isPublishing ? (
                <Spinner color={'white'} boxSize='18px' speed='1.5s' alignSelf={'center'} justifySelf='center' />
              ) : (
                <>
                  <Icon mr={2} boxSize={'icon-lg'}>
                    <path d={mdiCloudUploadOutline} />
                  </Icon>
                  {publisherStylesTexts[targetStatus].action(comparedStylesheet.status)}
                </>
              )}
            </Button>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default PublisherStylesFooter

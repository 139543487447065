import { ExternalComponent } from '@sitecore-feaas/sdk'
import MessageReceiverIframe from '../MessageReceiverIframe.js'
import React, { useContext, useState } from 'react'
import { useSDK } from '../../hooks/useData.js'
import { EnvironmentContext } from '../providers/EnvironmentProvider.js'

const PluginsRegistration = () => {
  const renderingHost = useSDK('renderingHost')
  const availableComponents = useSDK('renderingHost.availableComponents')
  const env = useContext(EnvironmentContext)

  return (
    <MessageReceiverIframe
      url={env.pluginsWebsite}
      onMessageWaiting={() => {
        renderingHost.set({ pluginsHostConnectionStatus: 'loading' })
      }}
      onMessageReceived={(message) => {
        if (message.action === 'register-components') {
          availableComponents.setItems(
            message.data.map((item: ExternalComponent) => ({
              id: item.name,
              ...item
            }))
          )
          console.log('FEAAS: Loaded plugins %O', availableComponents)
          // Wait for upcoming registration messages (ie. not all components registered in the first tick of page load)
          setTimeout(() => {
            renderingHost.set({ pluginsHostConnectionStatus: 'success' })
          }, 1000)
        } else if (message.action === 'register-datasources') {
          // TODO: FOR FUTURE REGISTRATION OF DATASOURCES FROM MARKETPLACE
        }
      }}
      onTimeout={() => {
        if (renderingHost.pluginsHostConnectionStatus === 'loading') {
          renderingHost.set({ pluginsHostConnectionStatus: 'error' })
        }
      }}
      timeout={10000}
    />
  )
}

export default PluginsRegistration

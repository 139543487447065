import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  VStack,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Link
} from '@chakra-ui/react'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'
import React from 'react'
import { useSDK } from '../../hooks/useData.js'
const UninstallDialog = ({ component }: { component: ExternalComponentModel }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const renderingHost = useSDK('renderingHost')
  const importStatement = component.getImportStatement()
  return (
    <>
      <Button variant={'primary'} onClick={onOpen} mb={6}>
        Uninstall
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uninstall instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Box fontSize={14} fontWeight={400}>
                <Text mb={6}>
                  In order to uninstall <b>{component.title}</b> for host <b>{renderingHost.url}</b> the following steps
                  need to take place:
                </Text>
                <UnorderedList ml={6} mb={6}>
                  <ListItem>
                    {' '}
                    Remove the import of <b>{component.title}</b> package us instructed below:
                  </ListItem>{' '}
                </UnorderedList>
                <Text mb={3}>
                  In <b>byoc/index.hybrid.tsx</b> file, ensure you have removed{' '}
                  {importStatement ? 'the following ' : 'the corresponding '} import statement
                </Text>

                <SyntaxHighlighter
                  id='plugin-install-code'
                  language={'jsx'}
                  style={dark}
                  customStyle={{
                    borderRadius: '4px',
                    fontSize: '14px',
                    flexShrink: '1',
                    overflow: 'auto',
                    margin: '0'
                  }}
                >
                  {importStatement
                    ? component.getImportStatement()
                    : ' // This is an example. Please use your component name \n import ./MyByocComponent'}
                </SyntaxHighlighter>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={'primary'} onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UninstallDialog

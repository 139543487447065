import { Box, Card, Flex, Icon, Spinner, Text } from '@chakra-ui/react'
import { ComponentModel, StylesheetModel, VersionModel } from '@sitecore-feaas/sdk'
import React from 'react'
import { useModelObserver } from '../../hooks/useData.js'
import VersionThumbnail from '../ComponentVersionThumbnail.js'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import { mdiChevronRight } from '@mdi/js'

const PublisherStylesComponent = ({
  component,
  currentStylesheet,
  comparedStylesheet,
  loading
}: {
  component: ComponentModel
  currentStylesheet: StylesheetModel
  comparedStylesheet: StylesheetModel
  loading: boolean
}) => {
  const versions = component.getVersions()
  return loading ? (
    <Box boxSize='24px' display='flex'>
      <Spinner boxSize='18px' speed='1.5s' alignSelf={'center'} justifySelf='center' />
    </Box>
  ) : (
    <Box>
      {versions.map((version, index) => (
        <Card key={index} my={6} p={3} borderWidth={'1px'} borderColor={'blackAlpha.200'}>
          <Flex margin={3} justifyContent={'center'} maxW={900}>
            <ComponentVersionThumbnailWrapper
              hasCheckerboard
              shadowExtent={5}
              flexBasis={'50%'}
              outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
            >
              <VersionThumbnail version={version} component={component} stylesheet={currentStylesheet} />
            </ComponentVersionThumbnailWrapper>
            <Flex alignItems={'center'} justifyContent={'center'}>
              <Icon m={2} fontSize='icon-xl'>
                <path d={mdiChevronRight} />
              </Icon>
            </Flex>
            <ComponentVersionThumbnailWrapper
              hasCheckerboard
              shadowExtent={5}
              flexBasis={'50%'}
              outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
            >
              <VersionThumbnail version={version} component={component} stylesheet={comparedStylesheet} />
            </ComponentVersionThumbnailWrapper>
          </Flex>
          <Box>
            Version:{' '}
            <Text display={'inline-block'} fontWeight={600}>
              {version.name}
            </Text>
          </Box>
        </Card>
      ))}
    </Box>
  )
}

export default PublisherStylesComponent

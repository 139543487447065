import { Badge, Box, Button, HStack, Image, Text, VStack, WrapItem } from '@chakra-ui/react'
import React from 'react'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
import { useSDK } from '../../hooks/useData.js'
import { useNavigate } from 'react-router-dom'
import { EmbeddableComponentThumbnail } from '../picker/PickerLibrary.js'

const PluginCard = ({ component }: { component: ExternalComponentModel }) => {
  const navigate = useNavigate()
  const registeredComponents = useSDK('renderingHost.registeredComponents')
  const availableComponents = useSDK('renderingHost.availableComponents')
  const isCustom = !availableComponents.find((c) => c.id === component.id)
  const isInstalled = !!registeredComponents.find((r) => r.name === component.name)
  return (
    <WrapItem
      w={430}
      h={270}
      p={6}
      key={component.id}
      layerStyle={'outline.interactive'}
      boxShadow={'0px 1px 3px 0px #0000001A'}
      alignItems={'stretch'}
      onClick={() => navigate(component.id)}
      cursor={'pointer'}
    >
      <VStack justifyContent={'space-between'} alignItems={'flex-start'} width={'100%'}>
        <Box alignSelf={'center'}>
          <EmbeddableComponentThumbnail component={component} height={160} px={'3'} pt={'3'} />
        </Box>
        <Box>
          <Text fontWeight={600} fontSize={14}>
            {component.title}
          </Text>
        </Box>
        <HStack alignSelf={'stretch'}>
          {component.links?.icon && <Image src={component.links?.icon} width={'24px'} />}

          <Badge
            colorScheme={isInstalled ? 'green' : 'gray'}
            display={'block'}
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            fontWeight={600}
          >
            {isInstalled ? 'INSTALLED' : 'NOT INSTALLED'}
          </Badge>
        </HStack>
      </VStack>
    </WrapItem>
  )
}

export default PluginCard

import { useState } from 'react'
import { Box, FormControl, FormLabel, Text, Textarea } from '@chakra-ui/react'

const TextareaField = (props: {
  value: string
  onChange: (value: string) => void
  label?: string
  required?: boolean
  error?: string
  onBlur?: any
  disabled?: boolean
  focus?: boolean
  placeholder?: string
  rows?: number
}) => {
  const { value, label, required, onChange, error, onBlur, disabled, focus, placeholder, rows } = props

  const [showErrors, setShowErrors] = useState(false)

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>
        {label}

        {required && (
          <Box display='inline' color='red' ml='1'>
            *
          </Box>
        )}
      </FormLabel>

      <Textarea
        rows={rows}
        placeholder={placeholder}
        bg='white'
        value={value}
        onChange={({ target }: { target: HTMLTextAreaElement }) => {
          onChange(target.value)

          setShowErrors(false)
        }}
        onBlur={() => {
          if (error) {
            setShowErrors(true)
          }
        }}
        disabled={disabled}
      />

      {showErrors && (
        <Text pos='absolute' mt={1} right='0' variant='microcopy' color='red' fontSize='10px'>
          {error}
        </Text>
      )}
    </FormControl>
  )
}

export default TextareaField

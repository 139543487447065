import React, { useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { useParams } from 'react-router'
import StylesList from '../components/styles/StylesList.js'
import StylesSidebar from '../components/styles/StylesSidebar.js'
import useDocumentTitle from '../hooks/useDocumentTitle.js'
import StylesFooter from '../components/styles/StylesFooter.js'

export default function Styles() {
  const { type: activeStyleType = 'font' } = useParams()
  useDocumentTitle('Styles')

  const [activeStylePage, setActiveStylePage] = useState(null)

  return (
    <Flex w='full' h='full'>
      <StylesSidebar activeStyleType={activeStyleType} setActiveStylePage={setActiveStylePage} />

      {activeStylePage && <StylesList activeStylePage={activeStylePage} />}
      <StylesFooter />
    </Flex>
  )
}

import { Box, Button, Container, Flex, Spacer, Text, Wrap } from '@chakra-ui/react'
import { CollectionModel, DatasourceModel, nanoid } from '@sitecore-feaas/sdk'
import { intersection, union } from 'lodash-es'
import React, { useCallback, useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import DatasourceBadge from '../components/DatasourceBadge.js'
import EmptyContent from '../components/EmptyContent.js'
import EntitiesProvider from '../components/entities/EntitiesProvider.js'
import LibraryCollection from '../components/library/LibraryCollection.js'
import { QueryStringContext } from '../components/providers/QueryStringProvider.js'
import {
  Filter,
  PageAction,
  PageContainer,
  PageDescription,
  PageTitle,
  Search as SearchComponent
} from '../components/styled.js'
import { useLibrary, useSDK } from '../hooks/useData.js'
import useDocumentTitle from '../hooks/useDocumentTitle.js'
import useFilter from '../hooks/useFilter.js'
import useNavigateWithQueryParams from '../hooks/useNavigateWithQueryParams.js'

const CollectionsRoute = () => {
  const library = useLibrary()
  const collections = useLibrary('collections').sort((a, b) =>
    a.isDefault ? 1 : b.isDefault ? -1 : new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
  )
  const components = useLibrary('components')
  let datasources = useSDK('datasources')
  const [query, setQuery] = useContext(QueryStringContext)
  const navigate = useNavigateWithQueryParams()
  useDocumentTitle(null)

  const { collectionId, componentId } = useParams()

  const searchTerm = (query.search || '').toLowerCase()

  const statuses = ['Draft', 'Changed', 'Staged', 'Published', 'Deleted'].map((status) => ({
    id: status.toLowerCase(),
    name: status
  }))

  const onSearchChange = (value: string) => setQuery({ search: value })
  const onSearchClear = () => setQuery({ search: null })
  const onClearAllFilters = () => setQuery({ status: null, collectionId: null, datasourceId: null, search: null })

  const allComponentIds = useMemo(() => components.map(({ id }) => id), [components])

  const [
    collectionOptions,
    collectionSelectedOptions,
    onCollectionOptionsChange,
    filteredCollectionIdsByCollectionIdFilter
  ] = useFilter<CollectionModel>(collections, 'collectionId')

  const [
    datasourceOptions,
    datasourceSelectedOptions,
    onDatasourceOptionsChange,
    filteredDatasourcesIdsByDatasourceIdFilter,
    isDatasourcesFilteringEnabled
  ] = useFilter<DatasourceModel>(datasources, 'datasourceId', 'type', {
    internal: <>External</>,
    xmTemplate: <DatasourceBadge datasources={[{ type: 'xmTemplate', name: '' }]} />,
    contentHubOne: <DatasourceBadge datasources={[{ type: 'contentHubOne', name: '' }]} />
  })
  const filteredComponentIdsByDatasourceIdFilter = isDatasourcesFilteringEnabled
    ? components
        .filter(({ datasourceIds }) =>
          filteredDatasourcesIdsByDatasourceIdFilter.find((id) => datasourceIds.includes(id))
        )
        .map(({ id }) => id)
    : allComponentIds

  const [
    statusOptions,
    statusSelectedOptions,
    onStatusOptionsChange,
    filteredStatusIdsByStatusIdFilter,
    isStatusFilteringEnabled
  ] = useFilter<{
    id: string
    name: string
  }>(statuses, 'status')
  const filteredComponentIdsByStatusIdFilter = isStatusFilteringEnabled
    ? components
        .filter(({ status }) => filteredStatusIdsByStatusIdFilter.includes(status.toLowerCase()))
        .map(({ id }) => id)
    : allComponentIds

  const filteredCollectionIdsByCollectionNameSearch = useMemo(
    () => collections.filter((collection) => collection.name.toLowerCase().includes(searchTerm)).map(({ id }) => id),
    [searchTerm, collections]
  )

  const filteredComponentIdsByComponentStatusSearch = useMemo(
    () => components.filter(({ status }) => status.toLowerCase().includes(searchTerm)).map(({ id }) => id),
    [searchTerm, components]
  )

  const filteredComponentIdsByComponentComponentNameSearch = useMemo(
    () => components.filter(({ name }) => name.toLowerCase().includes(searchTerm)).map(({ id }) => id),
    [searchTerm, components]
  )

  const filteredComponentIdsByComponentDescriptionSearch = useMemo(
    () => components.filter(({ description }) => description.toLowerCase().includes(searchTerm)).map(({ id }) => id),
    [searchTerm, components]
  )

  const filteredComponentIdsByComponentDatasourcesSearch = useMemo(
    () =>
      components
        .filter(
          ({ datasourceIds }) =>
            datasourceIds.filter((id) => {
              const datasource = datasources.find((d) => d.id === id)
              if (!datasource) return false
              return datasource.name.toLowerCase().includes(searchTerm)
            }).length
        )
        .map(({ id }) => id),
    [searchTerm, datasources, components]
  )

  const onCreateCollection = () => {
    const collection = collections.add({ name: '', id: nanoid(10) })
    navigate(`${library.getPath()}/collections/${collection.id}`)
  }

  const getComponentIds = useCallback(
    (collectionId: string) => {
      // Get the componentIds results from the intersection of component filters.
      let componentIds = intersection(filteredComponentIdsByDatasourceIdFilter, filteredComponentIdsByStatusIdFilter)

      if (searchTerm) {
        const fromSearch = union(
          union(
            filteredComponentIdsByComponentDatasourcesSearch,
            filteredComponentIdsByComponentStatusSearch,
            filteredComponentIdsByComponentComponentNameSearch,
            filteredComponentIdsByComponentDescriptionSearch
          ),
          ...filteredCollectionIdsByCollectionNameSearch.map((id) =>
            components.filter(({ collectionId }) => collectionId === id).map(({ id }) => id)
          )
        )

        // Intersect filter results with search results.
        componentIds = intersection(componentIds, fromSearch)
      }

      return componentIds.filter((_id) => components.find(({ id }) => id === _id).collectionId === collectionId)
    },
    [
      filteredComponentIdsByDatasourceIdFilter,
      filteredComponentIdsByStatusIdFilter,
      filteredComponentIdsByComponentDatasourcesSearch,
      filteredComponentIdsByComponentStatusSearch,
      filteredComponentIdsByComponentComponentNameSearch,
      filteredComponentIdsByComponentDescriptionSearch,
      searchTerm
    ]
  )

  const collectionIds = useMemo(() => {
    // Get the collectionIds results from the intersection of collection filters.
    let collectionIds = intersection(filteredCollectionIdsByCollectionIdFilter)

    if (searchTerm) {
      // If there is a search term combine the collection search results
      // and impactful component search results. That is cause component search results
      // have an impact on if a collection will be included in the final ids.
      const fromSearch = union(
        [
          ...new Set(
            union(
              filteredComponentIdsByComponentDatasourcesSearch,
              filteredComponentIdsByComponentStatusSearch,
              filteredComponentIdsByComponentComponentNameSearch,
              filteredComponentIdsByComponentDescriptionSearch
            ).map((componentId) => {
              const component = components.find((component) => component.id === componentId)
              return component.collectionId
            })
          )
        ],
        filteredCollectionIdsByCollectionNameSearch
      )

      // Intersect filter results with search results.
      collectionIds = intersection(collectionIds, fromSearch)
    }

    return collectionIds
  }, [filteredCollectionIdsByCollectionIdFilter, filteredCollectionIdsByCollectionNameSearch, searchTerm])

  const isSearched = !!query.search
  const isFilteredOrSearched = isSearched || query.collectionId || query.datasourceId || query.status
  const isComponentFilteredOrSearched = isSearched || query.datasourceId || query.status

  return (
    <Container {...PageContainer} id='overflow-wrapper'>
      <Flex {...PageDescription}>
        <Text {...PageTitle} className='page-title'>
          Components
        </Text>

        <Spacer />

        <Button {...PageAction} variant='secondary' size='md' onClick={onCreateCollection}>
          Add collection
        </Button>
      </Flex>

      <Wrap alignItems='center' mt='7' overflow='initial' className='filters'>
        <SearchComponent
          className='components-search'
          value={searchTerm}
          onChange={onSearchChange}
          onClear={onSearchClear}
        />

        <Filter
          placeholder='Collections'
          options={collectionOptions}
          onChange={onCollectionOptionsChange}
          value={collectionSelectedOptions}
        />

        <Filter
          placeholder='Data sources'
          options={datasourceOptions}
          onChange={onDatasourceOptionsChange}
          value={datasourceSelectedOptions}
        />

        <Filter
          placeholder='Status'
          options={statusOptions}
          onChange={onStatusOptionsChange}
          value={statusSelectedOptions}
        />

        <Button variant='subtle' size='md' onClick={onClearAllFilters}>
          Clear all
        </Button>
      </Wrap>
      {collectionIds.length > 0 ? (
        <EntitiesProvider
          url={`${library.getPath()}`}
          activeEntityId={!componentId && collectionId}
          pre='collections'
          ids={collectionIds}
        >
          <Box>
            {collectionIds.map((id) => {
              const componentIds = getComponentIds(id)

              return (
                (componentIds.length > 0 || !isComponentFilteredOrSearched) && (
                  <LibraryCollection
                    key={id}
                    id={id}
                    onClearAllFilters={onClearAllFilters}
                    componentIds={componentIds}
                  />
                )
              )
            })}
          </Box>
        </EntitiesProvider>
      ) : (
        <Box mt='100px'>
          <EmptyContent title='No results' subtitle='No results match your search' />
        </Box>
      )}
    </Container>
  )
}

export default CollectionsRoute

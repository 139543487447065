import { VersionModel } from '@sitecore-feaas/sdk'
import { Badge, Box, Checkbox, Flex, Icon, Text } from '@chakra-ui/react'
import VersionThumbnail from '../ComponentVersionThumbnail.js'
import { ChangeEvent } from 'react'
import { mdiAlertOutline, mdiChevronRight } from '@mdi/js'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import { ComponentsTargetStatus } from './PublisherComponents.js'

const PublisherComponentsVersion = ({
  version,
  targetStatus,
  selectedVersionIds,
  onSelect,
  isExpanded
}: {
  version: VersionModel
  targetStatus: ComponentsTargetStatus
  selectedVersionIds: string[]
  onSelect: (id: string[]) => void
  isExpanded: boolean
}) => {
  const { component } = version
  const current = version.getCurrent(targetStatus)

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) onSelect([...selectedVersionIds, version.getId()])
    else onSelect(selectedVersionIds.filter((id) => id !== version.getId()))
  }

  const handleClick = () => {
    if (selectedVersionIds.includes(version.getId()))
      onSelect(selectedVersionIds.filter((id) => id !== version.getId()))
    else onSelect([...selectedVersionIds, version.getId()])
  }

  return (
    <Box padding={3} className={'publisher-version'}>
      <Flex margin={3}>
        <Checkbox
          aria-label={'Select version'}
          colorScheme={'primary'}
          isChecked={selectedVersionIds.includes(version.getId())}
          onChange={handleCheck}
        >
          <Flex fontSize={'md'} fontWeight={500}>
            <Text color='gray.800'>Version: {version.name} </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex margin={3} justifyContent={'center'} onClick={handleClick}>
        <ComponentVersionThumbnailWrapper
          hasCheckerboard
          shadowExtent={5}
          flexBasis={'50%'}
          outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
        >
          {current ? (
            <VersionThumbnail version={current} component={component} />
          ) : (
            <Badge colorScheme={'red'} m='auto'>
              <Flex p={1} align='center'>
                <Icon boxSize={'icon-md'} mr='1'>
                  <path d={mdiAlertOutline} />
                </Icon>
                Version has not been {targetStatus} yet
              </Flex>
            </Badge>
          )}
        </ComponentVersionThumbnailWrapper>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Icon m={2} fontSize='icon-xl'>
            <path d={mdiChevronRight} />
          </Icon>
        </Flex>
        <ComponentVersionThumbnailWrapper
          hasCheckerboard
          shadowExtent={5}
          flexBasis={'50%'}
          outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
        >
          <VersionThumbnail version={version} component={component} />
        </ComponentVersionThumbnailWrapper>
      </Flex>
    </Box>
  )
}

export default PublisherComponentsVersion

import { Box, Button, Container, Flex, HStack, Icon, Text, useToast } from '@chakra-ui/react'
import { ComponentModel, isDeepEquals, VersionModel } from '@sitecore-feaas/sdk'
import { useMemo } from 'react'
import { ComponentsTargetStatus } from './PublisherComponents.js'
import { mdiAlertCircle, mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { useLibrary } from '../../exports.js'
import { useNavigate } from 'react-router'

const PublisherComponentsFooter = ({
  targetStatus,
  versions,
  selectedVersionIds,
  onPublish
}: {
  targetStatus: ComponentsTargetStatus
  versions: VersionModel[]
  selectedVersionIds: string[]
  onPublish: () => void
}) => {
  const stylesheet = useLibrary('stylesheets.first')

  const navigate = useNavigate()
  const toast = useToast()

  const firstTimeVersions = useMemo<VersionModel[]>(
    () => versions?.filter((ver) => !ver.getCurrent(targetStatus)),
    [versions]
  )
  const selectedFirstTimeVersions = useMemo<VersionModel[]>(
    () => firstTimeVersions.filter((ver) => selectedVersionIds.includes(ver.getId())),
    [firstTimeVersions, selectedVersionIds]
  )

  const upToDateStylesheet = stylesheet.isUpToDateWith(targetStatus)

  const publish = async () => {
    const componentsToBeUpdated: { [id: string]: ComponentModel } = {}
    try {
      for (const version of versions) {
        componentsToBeUpdated[version.component.id] = version.component
        if (selectedVersionIds.includes(version.getId())) {
          if (targetStatus === 'staged') {
            await version.stage()
          } else {
            await version.stage().publish()
          }
        }
      }
      for (const id in componentsToBeUpdated) {
        const component = componentsToBeUpdated[id]
        const saved = await component.saveVersions()
        if (saved.includes(undefined)) throw new Error('Could not save version')
        component.aggregateVersionData()
        await component.library.components.replaceItem(component)
      }
      onPublish()
      requestAnimationFrame(() => {
        toast({
          duration: 4000,
          status: 'success',
          title: `Versions ${targetStatus} successfully`
        })
      })
    } catch (e) {
      requestAnimationFrame(() => {
        toast({
          duration: 4000,
          status: 'error',
          title: `There was an error trying to ${targetStatus === 'staged' ? 'stage' : 'publish'} versions`,
          description: e.message
        })
      })
    }
  }

  return (
    <Box borderTop={'1px solid rgba(0, 0, 0, 0.1)'} position={'fixed'} bottom={0} width={'100%'}>
      {!upToDateStylesheet && (
        <Box backgroundColor={'primary.100'} height={'100%'}>
          <Container maxW='container.xl' pl={10} pr={10} py={4}>
            <Flex justifyContent={'space-between'}>
              <HStack>
                <Icon boxSize={'icon-xl'}>
                  <path color={'#5548D9'} d={mdiAlertCircle} />
                </Icon>
                <Text alignSelf={'center'}>
                  {targetStatus === 'staged'
                    ? 'Staged components in the Pages might look differently than in Components product as they use different style libraries'
                    : 'Components published in production and visible on the live site may look different as the style library is not up-to-date'}
                </Text>
              </HStack>
              <Button
                variant={'primary'}
                backgroundColor={'blackAlpha.500'}
                onClick={() => navigate(`styles-${targetStatus}`)}
              >
                Go to style publishing
              </Button>
            </Flex>
          </Container>
        </Box>
      )}

      <Box backgroundColor={'gray.700'} color={'white'} height={16}>
        <Container maxW='container.xl' pl={10} pr={10} py={4}>
          <Flex justifyContent={'space-between'}>
            <Text alignSelf={'center'}>
              {selectedVersionIds.length - selectedFirstTimeVersions.length + selectedFirstTimeVersions.length > 0 && (
                <>
                  <b>Changes:</b> &nbsp;
                  <>
                    {selectedVersionIds.length - selectedFirstTimeVersions.length ? (
                      <>
                        {selectedVersionIds.length - selectedFirstTimeVersions.length} out of{' '}
                        {versions?.length - firstTimeVersions.length} versions will be re-
                        {targetStatus}.
                      </>
                    ) : (
                      <>No versions will be re-{targetStatus}.</>
                    )}
                  </>
                  &nbsp;
                  <>
                    {selectedFirstTimeVersions.length ? (
                      <>
                        {selectedFirstTimeVersions.length} out of {firstTimeVersions.length} not {targetStatus} versions
                        will be {targetStatus}.
                      </>
                    ) : (
                      <>No versions will be {targetStatus}.</>
                    )}
                  </>
                </>
              )}
            </Text>
            <Button
              width='102px'
              justifyContent={'center'}
              isDisabled={!selectedVersionIds.length}
              onClick={publish}
              variant='primary'
              size='sm'
            >
              <Icon mr={2} boxSize={'icon-lg'}>
                <path d={mdiCloudUploadOutline} />
              </Icon>
              {targetStatus === 'staged' ? 'Stage' : 'Publish'}
            </Button>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default PublisherComponentsFooter

import { useEffect, useRef } from 'react'
import Navbar from '../Navbar.js'
import { Box, Flex, FlexProps, Tooltip, VStack } from '@chakra-ui/react'

const BasicLayout = ({ children, ...props }: FlexProps) => {
  const portalRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    Tooltip.defaultProps.portalProps = {
      containerRef: portalRef
    }
  }, [])
  return (
    <>
      <Box
        ref={portalRef}
        position='fixed'
        top={0}
        left={0}
        height={'100vh'}
        width={'100vw'}
        overflow='hidden'
        pointerEvents='none'
        zIndex='1000'
      ></Box>
      <VStack
        id='layout'
        feaas-role='ui'
        alignItems={'stretch'}
        flexGrow={1}
        spacing={0}
        minWidth='900px'
        sx={{
          '&:not(.fixed-height-layout)': {
            overflowY: 'scroll'
          }
        }}
      >
        <Navbar />

        <Flex
          flexGrow={1}
          direction={'column'}
          sx={{
            '.fixed-height-layout &': {
              height: 'calc(100vh - 60px)'
            }
          }}
        >
          {children}
        </Flex>
      </VStack>
    </>
  )
}

export default BasicLayout

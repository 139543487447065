import { Box, Button, Flex, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import {PublisherVariant} from '../../routes/Publishing.js'
import { useLibrary } from '../../hooks/useData.js'
import { mdiAlertCircle } from '@mdi/js'
import { useNavigate } from 'react-router'
import {StylesheetModel} from "@sitecore-feaas/sdk";

const PublisherSidebar = ({ variant, targetStatus }: { variant: PublisherVariant, targetStatus: StylesheetModel['status'] }) => {
  const navigate = useNavigate()
  const components = useLibrary('components')
  const stylesheets = useLibrary('stylesheets')
  const stylesheet = useLibrary('stylesheets.first')
  const stagedComponentsUpToDate = !components.find((component) =>
    component.getVersions().find((version) => version.status === 'saved' && !version.deletedAt)
  )
  const publishedComponentsUpToDate = !components.find((component) =>
    component.getVersions().find((version) => version.status === 'staged' && !version.deletedAt)
  )
  const stagedStylesUpToDate = stylesheet.getCurrent('staged').isUpToDateWith('saved')
  const publishedStylesUpToDate = stylesheet.getCurrent('published').isUpToDateWith('saved')

  return (
    <Box background={'gray.50'} height={'full'} width={'64'} className='sidebar'>
      <Box background={'gray.50'} overflowY={'auto'} py={'5'} px={3} top={'58px'} bottom={'0px'}>
        <Stack>
          <Text fontWeight={700} fontSize={'12px'} color={'blackAlpha.500'}>
            COMPONENTS
          </Text>
          <Button
            onClick={() => {
              navigate(`components/staged`)
            }}
            variant={variant === 'components' && targetStatus === 'staged' ? 'navVerticalActive' : 'navVertical'}
          >
            <HStack justifyContent={'space-between'} width={'100%'}>
              <Text>Ready for staging</Text>{' '}
              {!stagedComponentsUpToDate && (
                <Tooltip label={'Components are not up to date'}>
                  <Icon boxSize={'icon-lg'}>
                    <path color={'#D97706'} d={mdiAlertCircle} />
                  </Icon>
                </Tooltip>
              )}
            </HStack>
          </Button>
          <Button
            onClick={() => {
              navigate(`components/published`)
            }}
            variant={variant === 'components' && targetStatus === 'published' ? 'navVerticalActive' : 'navVertical'}
          >
            <HStack justifyContent={'space-between'} width={'100%'}>
              <Text>Ready for publishing</Text>{' '}
              {!publishedComponentsUpToDate && (
                <Tooltip label={'Components are not up to date'}>
                  <Icon boxSize={'icon-lg'}>
                    <path color={'#D97706'} d={mdiAlertCircle} />
                  </Icon>
                </Tooltip>
              )}
            </HStack>
          </Button>
          <Text fontWeight={700} fontSize={'12px'} color={'blackAlpha.500'}>
            STYLES
          </Text>
          <Button
            onClick={() => {
              navigate(`styles/saved`)
            }}
            variant={variant === 'styles' && targetStatus === 'saved' ? 'navVerticalActive' : 'navVertical'}
          >
            Draft
          </Button>
          <Button
            onClick={() => {
              navigate(`styles/staged`)
            }}
            variant={variant === 'styles' && targetStatus === 'staged' ? 'navVerticalActive' : 'navVertical'}
          >
            <HStack justifyContent={'space-between'} width={'100%'}>
              <Text>Staged</Text>{' '}
              {!stagedStylesUpToDate && (
                <Tooltip label={'Styles are not up to date'}>
                  <Icon boxSize={'icon-lg'}>
                    <path color={'#D97706'} d={mdiAlertCircle} />
                  </Icon>
                </Tooltip>
              )}
            </HStack>
          </Button>
          <Button
            onClick={() => {
              navigate(`styles/published`)
            }}
            variant={variant === 'styles' && targetStatus === 'published' ? 'navVerticalActive' : 'navVertical'}
          >
            <HStack justifyContent={'space-between'} width={'100%'}>
              <Text>Published</Text>{' '}
              {!publishedStylesUpToDate && (
                <Tooltip label={'Styles are not up to date'}>
                  <Icon boxSize={'icon-lg'}>
                    <path color={'#D97706'} d={mdiAlertCircle} />
                  </Icon>
                </Tooltip>
              )}
            </HStack>
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default PublisherSidebar

import SettingsCard from './SettingsCard.js'
import { LibraryModel, Style } from '@sitecore-feaas/sdk'
import { useLibrary, useSDK } from '../../hooks/useData.js'
import React, { useMemo, useContext, useState, useEffect } from 'react'
import Preview, { PreviewFontsStylesheet } from '../styles/previews/index.js'
import { Badge, Box, Button, Divider, Grid, GridItem, HStack, Icon, Text, useToast } from '@chakra-ui/react'
import { ConfirmationContext } from '../providers/ConfirmationProvider.js'
import { mdiAlertCircleOutline, mdiAlertOutline } from '@mdi/js'

const StyleLibraries = () => {
  const targetLibrary = useLibrary()
  const templateLibraries = useSDK('templateLibraries')
  const stylesheetTemplateLibraries = useMemo(
    () =>
      templateLibraries.filter((lib) => {
        const { templates } = lib.settings as { templates: string[] }
        return templates.includes('stylesheets') && lib.id !== targetLibrary.id
      }),
    [templateLibraries]
  )
  const [appliedLibraryId, setAppliedLibraryId] = useState<string>()
  const isShared = targetLibrary.settings.templates?.includes('stylesheets')

  return (
    <SettingsCard
      name={'Shared style libraries'}
      description={'Replace your style library with an out-of-the-box or a shared library'}
    >
      <Box>
        <Badge colorScheme={'blue'} my={2} mr='2' px={2} fontSize={'md'}>
          <Icon mr={1}>
            <path d={mdiAlertCircleOutline} />
          </Icon>{' '}
          Replacing style library will discard the current styles. It will possible to revert to the previously staged
          state.
        </Badge>
      </Box>
      <Grid
        display='grid'
        gap='24px'
        gridTemplateColumns={'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'}
        sx={{
          '@media (max-width: 1100px)': {
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
          }
        }}
        mt='5'
      >
        {stylesheetTemplateLibraries?.map((lib, index) => (
          <Stylesheet
            key={lib.id}
            lib={lib}
            targetLibrary={targetLibrary}
            isApplied={appliedLibraryId === lib.id}
            onApply={setAppliedLibraryId}
          />
        ))}
      </Grid>
    </SettingsCard>
  )
}

const Stylesheet = ({
  lib,
  targetLibrary,
  isApplied,
  onApply
}: {
  lib: LibraryModel
  targetLibrary: LibraryModel
  isApplied: boolean
  onApply: (id: string) => void
}) => {
  const { setConfirm } = useContext(ConfirmationContext)
  const toast = useToast()
  const theme = Style.Set.findBySlug(lib.stylesheet.rules, 'theme', 'default')
  const numberOfThemes = Style.Set.filterByType(lib.stylesheet.rules, 'theme').length
  const handleApply = () =>
    setConfirm({
      action: () => {
        lib.stylesheet.copyToLibrary(targetLibrary, 'saved').then(() => {
          onApply(lib.id)
          toast({
            duration: 4000,
            status: 'success',
            title: 'Stylesheet changed'
          })
        })
      },
      title: `Replace style library`,
      button: 'Replace',
      body: `By replacing your current library with ${lib.name} will delete your current library. Are you sure you want to proceed?`,
      variant: 'danger'
    })

  return (
    <GridItem colSpan={1}>
      <Box
        className={'stylesheet-card'}
        flexBasis='320px'
        height='100%'
        flexShrink={1}
        borderRadius='base'
        boxShadow='base'
        bg='white'
        p={6}
      >
        {theme && (
          <Box slot='preview'>
            <PreviewFontsStylesheet styles={lib.stylesheet.rules} />
            <Preview variant='compact' rule={theme as Style.Rule} rules={lib.stylesheet.rules} />
          </Box>
        )}
        <Box slot='details' minWidth='1px' flexGrow={1} mt={6}>
          <HStack alignItems={'center'} height={'20px'}>
            <Text
              fontWeight={'semibold'}
              maxWidth={'320px'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              flexShrink={0.1}
              flexBasis={'max-content'}
            >
              {lib.name}
            </Text>

            <Divider orientation={'vertical'} />
            <Text
              fontWeight={'normal'}
              marginLeft={'2'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              flexShrink={1000}
              flexGrow={1}
              flexBasis={'50%'}
            >
              {numberOfThemes} theme{numberOfThemes !== 1 ? 's' : ''} available
            </Text>
            <Button isDisabled={isApplied} variant={'primary'} size={'sm'} onClick={handleApply}>
              {isApplied ? 'Current' : 'Replace'}
            </Button>
          </HStack>
          {lib.organizationId && (
            <Badge colorScheme={'teal'} mt={3} mr='2' px={2} fontSize={'md'}>
              Shared library
            </Badge>
          )}
        </Box>
      </Box>
    </GridItem>
  )
}

export default StyleLibraries

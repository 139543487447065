import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  Text
} from '@chakra-ui/react'
import {
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiHelpCircleOutline,
  mdiHomeVariantOutline,
  mdiLogout
} from '@mdi/js'
import React, { useContext, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useData, useLibrary, useSDK } from '../hooks/useData.js'
import { AppSwitcher } from './AppSwitcher.js'
import LibrarySwitcher from './LibrarySwitcher.js'
import { EnvironmentContext } from './providers/EnvironmentProvider.js'
import { QueryStringContext } from './providers/QueryStringProvider.js'

export const Wrapper: FlexProps = {
  width: 'full',
  background: 'white',
  position: 'sticky',
  paddingTop: '3',
  paddingBottom: '3',
  paddingLeft: '3',
  paddingRight: '22px',
  top: 0,
  boxShadow: 'base',
  alignItems: 'center',
  maxHeight: '14',
  zIndex: 200
}

const Navbar = () => {
  const navigate = useNavigate()
  const sdk = useSDK()
  const tenant = useSDK('auth.tenant')
  const env = useContext(EnvironmentContext)
  const library = useLibrary()
  const { user, logout } = useSDK('auth')
  const ref = useRef<HTMLDivElement>()
  const [query] = useContext(QueryStringContext)

  return (
    <Flex {...Wrapper} ref={ref} as='header' id='navbar'>
      <Flex align='center' w='full' id='navigation-horizontal'>
        {library && <AppSwitcher />}

        <Button
          p={0}
          ml='6px'
          onClick={() => {
            location.href = `${env.xmCloudDashboard}?tenantName=${tenant.name}&organization=${tenant.organizationId}`
          }}
        >
          <Icon color='blackAlpha.600' boxSize='icon-xl'>
            <path d={mdiHomeVariantOutline} />
          </Icon>
        </Button>

        <Icon color='blackAlpha.500' boxSize='20px' border='0'>
          <path d={mdiChevronRight} />
        </Icon>
        <Image
          ml='4px'
          width='133px'
          height='24px'
          src='https://mss-p-006-delivery.stylelabs.cloud/api/public/content/3997aaa0d8be4eb789f3b1541bd95c58?v=ecc6e45b'
          onClick={() => navigate(library?.getPath())}
          cursor='pointer'
        />
        {library && (
          <>
            <ButtonGroup as='nav' size='sm' variant='navHorizontal' ml='19px'>
              <Button
                onClick={() => navigate(`${library.getPath()}/collections`)}
                variant={
                  Boolean(
                    location.pathname.match(
                      /^(?!\/libraries\/[^/]+\/(?:datasources|styles|publishing|settings|plugins))/
                    )
                  )
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Components
              </Button>

              <Button
                onClick={() => navigate(`${library.getPath()}/styles`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/styles`) ? 'navHorizontalActive' : 'navHorizontal'
                }
              >
                Styles
              </Button>

              <Button
                onClick={() => navigate(`${library.getPath()}/datasources`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/datasources`)
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Data sources
              </Button>

              <Button
                onClick={() => navigate(`${library.getPath()}/publishing/components/staged`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/publishing`)
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Publishing
              </Button>
              <Button
                onClick={() => navigate(`${library.getPath()}/settings`)}
                variant={
                  location.pathname.startsWith(`${library.getPath()}/settings`)
                    ? 'navHorizontalActive'
                    : 'navHorizontal'
                }
              >
                Settings
              </Button>
              {env.inventoryEnv === 'staging' && (
                <Button
                  onClick={() => navigate(`${library.getPath()}/plugins`)}
                  variant={
                    location.pathname.startsWith(`${library.getPath()}/plugins`)
                      ? 'navHorizontalActive'
                      : 'navHorizontal'
                  }
                >
                  Plugins
                </Button>
              )}
            </ButtonGroup>
          </>
        )}
        <Flex ml='auto' minW={0}>
          {library && false && <LibrarySwitcher navigate={navigate} />}

          {false && (
            <Flex align='center' ml='5'>
              <Icon boxSize='icon-xl'>
                <path d={mdiHelpCircleOutline} />
              </Icon>
            </Flex>
          )}

          <Flex align='center' ml='5'>
            <Menu isLazy>
              <MenuButton>
                <Avatar size='sm' name={user?.name} />
              </MenuButton>

              <MenuList boxShadow={'1px 1px 6px 0.5px lightgrey'}>
                {user && (
                  <>
                    <MenuItem
                      p={4}
                      onClick={() =>
                        window.open(
                          `https://portal${env.inventoryEnv === 'production' ? '' : '-' + env.inventoryEnv}.${
                            env.sitecorePostfix
                          }/profile/manage?organization=${tenant.organizationId}`
                        )
                      }
                    >
                      <Box>
                        <Text fontSize={16} fontWeight={600}>
                          {user.given_name} {user.family_name}
                        </Text>
                        <Text>{user.email}</Text>
                      </Box>
                    </MenuItem>
                    <MenuItem
                      p={4}
                      onClick={() =>
                        window.open(
                          `https://portal${env.inventoryEnv === 'production' ? '' : '-' + env.inventoryEnv}.${
                            env.sitecorePostfix
                          }/profile/manage?organization=${tenant.organizationId}`
                        )
                      }
                    >
                      <HStack>
                        <Icon>
                          <path d={mdiAccountCircleOutline} />
                        </Icon>
                        <Text fontSize={16} fontWeight={600}>
                          Manage account
                        </Text>
                      </HStack>
                    </MenuItem>
                  </>
                )}
                {import.meta.env.INVENTORY_ENV === 'staging' && (
                  <MenuItem
                    p={4}
                    onClick={() => {
                      sdk.tenants.search({ systemId: env.contentHubOneSystemId }).then(async (tenants) => {
                        if (tenants.length) {
                          logout({
                            returnTo: `${window.location.origin}?tenantName=${tenants[0].name}&systemId=${env.contentHubOneSystemId}&organization=${tenants[0].organizationId}`
                          })
                        }
                      })
                    }}
                  >
                    <Tooltip label='Switch to first available Content Hub One tenant'>
                      <img
                        style={{ height: '18px' }}
                        src='https://mss-p-006-delivery.stylelabs.cloud/api/public/content/91c3d57209b042ff9aacfee56125ef0e'
                        alt={'content hub one'}
                      />
                    </Tooltip>
                  </MenuItem>
                )}
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })} p={4}>
                  <HStack>
                    <Icon>
                      <path d={mdiLogout} />
                    </Icon>
                    <Text fontWeight={600} fontSize={16}>
                      Logout
                    </Text>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Navbar

export function updateQueryParam(param: { key: string; value: string }) {
  const { key, value } = param

  const url = new URL(window.location.href)

  if (value) {
    url.searchParams.set(key, value)
  } else {
    url.searchParams.delete(key)
  }

  window.history.pushState({}, '', url)
}

export function getQueryParam({ key }: { key: string }) {
  const url = new URL(window.location.href)

  return url.searchParams.get(key)
}

export function urlContains(segment: string) {
  return window.location.href.includes('segment')
}

export function getSegment(index: number) {
  const split = window.location.href.split('/')

  return split[2 + index]
}

export function getLibraryFromPathname(pathname: string) {
  return pathname.match(/(libraries\/)(.*?)(?=\?|\/|$)/)?.[2]
}

export function getGithubRawReadmeUrl(repoUrl: string, _branch?: string) {
  if (!repoUrl) return null
  const urlParts = repoUrl.split('/')
  const owner = urlParts[3]
  const repo = urlParts[4]
  const branch = _branch || urlParts[6] || 'main' // Default to 'main' branch if not specified
  const path = urlParts.slice(7).join('/')
  const rawReadmeUrl = `https://raw.githubusercontent.com/${owner}/${repo}/${branch}/${path ? path + '/' : ''}README.md`
  return rawReadmeUrl
}

import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Icon,
  MenuDivider,
  MenuItem,
  StackProps,
  Tag,
  TagProps,
  Text,
  TextProps
} from '@chakra-ui/react'
import { mdiCodeTags, mdiContentCopy, mdiOpenInNew } from '@mdi/js'
import { CollectionModel, isDeepEquals } from '@sitecore-feaas/sdk'
import React, { useContext, useEffect, useState } from 'react'
import ComponentVersionThumbnail from '../../components/ComponentVersionThumbnail.js'
import EntityProvider from '../../components/entities/EntityProvider.js'
import TextareaField from '../../components/TextareaField.js'
import { useData, useLibrary, useSDK } from '../../hooks/useData.js'
import useNavigateWithQueryParams from '../../hooks/useNavigateWithQueryParams.js'
import { Option } from '../../types/index.js'
import { formatDate } from '../../utils/date.js'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import DatasourceBadge from '../DatasourceBadge.js'
import VersionEmbed from '../editor/version/VersionEmbed.js'
import FieldsetField from '../FieldsetField.js'
import { ConfirmationContext } from '../providers/ConfirmationProvider.js'
import { Select } from '../Select.js'
import StatusBadge from '../StatusBadge.js'
import TextField from '../TextField.js'
import { useParams } from 'react-router-dom'

const ThumbnailWrapper = {
  padding: 0,
  border: '1px solid blackAlpha.300'
}

export const ComponentDetails: TextProps = {
  alignItems: 'center',
  height: '20px'
}

export const ComponentTitle: TextProps = {
  fontWeight: 'semibold',
  maxWidth: '320px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 0.1,
  flexBasis: 'max-content'
}

export const ComponentDescription: TextProps = {
  fontWeight: 'normal',
  marginLeft: '2',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexShrink: 1000,
  flexGrow: 1,
  flexBasis: '50%'
}

export const ComponentDetailsSeparator = {
  marginLeft: '2'
}

export const Field = {
  marginTop: '5'
}

export const InnerDetails: StackProps = {
  marginTop: '3',
  spacing: 3
}

export const Datasource = {
  flexShrink: 1
}

const LibraryCollectionComponent = ({ id }: { id: string }) => {
  const collections = useLibrary('collections').sort((a: CollectionModel, b: CollectionModel) =>
    a.isDefault ? -1 : a.name.localeCompare(b.name)
  )
  const datasources = useSDK('datasources')
  const components = useLibrary('components')
  const library = useLibrary()
  const navigate = useNavigateWithQueryParams()
  const { collectionId, componentId } = useParams()
  const { setConfirm } = useContext(ConfirmationContext)
  const collection = collections.find(({ id }) => id === collectionId)

  const component = components.find(({ id: componentId }) => componentId === id)

  const [errors, setErrors] = useState(null)
  const [currentComponent, setCurrentComponent] = useState(component.clone())

  const [versionsSaved, setVersionsSaved] = useState(false)

  useEffect(() => {
    if (component.versionCount === 0) return
  }, [])

  useEffect(() => {
    setErrors(getErrors())
  }, [currentComponent])

  useEffect(() => {
    setCurrentComponent(component)
  }, [component])

  const hasChanges = !currentComponent.isEqual(component)
  const hasErrors = errors !== null
  const collectionOptions: Option[] = collections.map((collection) => ({
    label: collection.name,
    value: collection.id
  }))

  const onDelete = () => component.delete()

  const onCopy = async () => {
    await component.versions.fetch()
    const c = component.duplicate(component, components)
    components.add(c)
    navigate(`${library.getPath()}/collections/${c.collectionId}/components/${c.id}`)
  }

  const getErrors = () => {
    const errors: any = {}

    if (
      components.find(
        ({ name, id, collectionId }) =>
          name === currentComponent.name && id !== currentComponent.id && currentComponent.collectionId === collectionId
      )
    ) {
      errors.name = 'There is already a component with that name'
    }

    if (!currentComponent.name) {
      errors.name = "Name can't be empty"
    }

    return Object.keys(errors).length ? errors : null
  }

  const currentComponentCollection = collections.find(({ id }) => id === currentComponent.collectionId)

  const firstVersionId = component?.versions[0]?.id

  const onEmbed = async () => {
    await component.versions.fetch()

    setConfirm({
      type: 'modal',
      title: 'Embedding code',
      body: <VersionEmbed componentId={component.id} id={firstVersionId} />,
      button: (
        <>
          <Icon boxSize='icon-xl' mr='2'>
            <path d={mdiContentCopy} />
          </Icon>
          Copy code
        </>
      ),
      action: () => {
        navigator.clipboard.writeText(document.querySelector('#generated-code').textContent)
      }
    })
  }

  const componentDatasources = datasources.filter((ds) => component.datasourceIds.includes(ds.id))

  const emptyRequiredFields = [{ title: 'Name', value: currentComponent.name }]
    .filter(({ title, value }) => !value)
    .map(({ title }) => title)

  return (
    <EntityProvider
      mode='overlay'
      key={id}
      id={id}
      onDelete={onDelete}
      hasChanges={hasChanges}
      onDiscard={() => setCurrentComponent(component)}
      emptyRequiredFields={emptyRequiredFields}
      invalidNonEmptyFields={[]}
      hasErrors={hasErrors}
      onCopy={onCopy}
      onSave={async () => {
        setVersionsSaved(false)
        await currentComponent.save()
        await Promise.all(component.versions.map((v) => v.post()))
        setVersionsSaved(true)
      }}
      isNew={component.isNew}
      name='component'
    >
      <Box
        slot='preview'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (component.isNew || hasChanges) return
          navigate(`${library.getPath()}/components/${component.id}`)
        }}
      >
        <ComponentVersionThumbnailWrapper
          {...ThumbnailWrapper}
          height={280}
          pointerEvents={component?.isNew || hasChanges ? 'none' : null}
        >
          <ComponentVersionThumbnail
            targetHeight={280}
            component={component}
            version={component.isNew || versionsSaved ? component.getVersionForWidth(800) : undefined}
          >
            <Text
              {...{
                color: 'primary.600',
                fontWeight: 'semibold',
                marginTop: '3'
              }}
            >
              {component?.isNew ? 'Creating new component' : 'Click to start editing'}
            </Text>
          </ComponentVersionThumbnail>
        </ComponentVersionThumbnailWrapper>
      </Box>

      <Box slot='details' minWidth='1px' flexGrow={1}>
        {component.name && (
          <Flex {...ComponentDetails}>
            <Text {...ComponentTitle}>{component.name}</Text>

            {component.description && <Box {...ComponentDetailsSeparator}>|</Box>}
            <Text {...ComponentDescription}>{component.description}</Text>
          </Flex>
        )}

        <HStack {...InnerDetails}>
          <StatusBadge status={component.status} />
          <Tag colorScheme='purple' flexShrink={0}>
            {component.versionCount} {`version${component.versionCount !== 1 ? 's' : ''}`}
          </Tag>
          {componentDatasources.length > 0 && <DatasourceBadge {...Datasource} datasources={componentDatasources} />}
        </HStack>
      </Box>

      <Box slot='form'>
        <FieldsetField label='Name' isChanged={currentComponent.name !== component.name}>
          <TextField
            error={errors?.name}
            value={currentComponent.name}
            onChange={(name) => setCurrentComponent(currentComponent.change({ name }))}
          />
        </FieldsetField>

        <FieldsetField
          {...Field}
          label='Description'
          isChanged={currentComponent.description !== component.description}
        >
          <TextareaField
            value={currentComponent.description}
            onChange={(description) => setCurrentComponent(currentComponent.change({ description }))}
          />
        </FieldsetField>

        <FieldsetField
          {...Field}
          label='Collection'
          isChanged={currentComponent.collectionId !== component.collectionId}
        >
          <Select
            value={{
              label: currentComponentCollection.name,
              value: currentComponentCollection.id
            }}
            options={collectionOptions}
            onChange={({ value: collectionId }) => setCurrentComponent(currentComponent.change({ collectionId }))}
          />
        </FieldsetField>
      </Box>

      <MenuItem
        slot='extraMenuActions'
        isDisabled={hasChanges || component.isNew}
        onClick={() => navigate(`${library.getPath()}/components/${component.id}`)}
        icon={
          <Icon boxSize='icon-xl'>
            <path d={mdiOpenInNew} />
          </Icon>
        }
      >
        Open in builder
      </MenuItem>

      {firstVersionId && (
        <MenuItem
          slot='extraMenuActions'
          isDisabled={component.isNew}
          icon={
            <Icon boxSize='icon-xl'>
              <path d={mdiCodeTags} />
            </Icon>
          }
          onClick={onEmbed}
        >
          Get embedding code
        </MenuItem>
      )}

      <MenuDivider slot='extraMenuActions' />

      <MenuDivider slot='menuDetails' />

      <MenuItem cursor='auto' _hover={{ background: 'none' }} slot='menuDetails'>
        <Box>
          <Flex>
            <Text color='blackAlpha.500' fontWeight='600'>
              Updated on:
            </Text>
            <Text ml='2'>{formatDate(component.modifiedAt)}</Text>
          </Flex>

          <Flex>
            <Text color='blackAlpha.500' fontWeight='600'>
              Updated by:
            </Text>
            <Text ml='2'> {(component.modifiedBy as any).name}</Text>
          </Flex>

          {component.datasourceIds.length > 0 && (
            <Flex>
              <Text color='blackAlpha.500' fontWeight='600'>
                Data sources:
              </Text>
              <Box>
                {component.datasourceIds.map((dsId) => (
                  <Text key={dsId} noOfLines={1} ml='2'>
                    {datasources.find(({ id }) => id === dsId)?.name}
                  </Text>
                ))}
              </Box>
            </Flex>
          )}
        </Box>
      </MenuItem>
    </EntityProvider>
  )
}

export default LibraryCollectionComponent

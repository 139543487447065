import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Badge, Text, TextProps, BoxProps, GridItem, Grid } from '@chakra-ui/react'
import { isDeepEquals } from '@sitecore-feaas/sdk'
import EntitiesProvider from '../entities/EntitiesProvider.js'
import EntityProvider from '../entities/EntityProvider.js'
import EmptyContent from '../EmptyContent.js'
import FieldsetField from '../FieldsetField.js'
import FlexGrid from '../FlexGrid.js'
import TextField from '../TextField.js'
import { useData, useLibrary } from '../../hooks/useData.js'
import { useParams } from 'react-router'
import LibraryCollectionComponent from './LibraryCollectionComponent.js'
import useNavigateWithQueryParams from '../../hooks/useNavigateWithQueryParams.js'
import { QueryStringContext } from '../providers/QueryStringProvider.js'

export const CollectionTitle: TextProps = {
  fontSize: '3xl',
  fontWeight: 'semibold'
}

export const NoComponents = {
  marginTop: '8'
}

export const Field = {
  width: '320px'
}

export const Details = {
  alignItems: 'center'
}

const LibraryCollection = ({
  id,
  onClearAllFilters,
  componentIds
}: {
  id: string
  onClearAllFilters: () => void
  componentIds: string[]
}) => {
  const collections = useLibrary('collections')
  const library = useLibrary()
  const components = useLibrary('components')
  const { collectionId, componentId } = useParams()
  const navigate = useNavigateWithQueryParams()
  const [query, setQuery] = useContext(QueryStringContext)

  const collection = collections.find(({ id: collectionId }) => collectionId === id)

  const [errors, setErrors] = useState(null)
  const [currentCollection, setCurrentCollection] = useState(collection.clone())

  const getErrors = () => {
    const errors: any = {}

    if (collections.find(({ name, id }) => name === currentCollection.name && id !== currentCollection.id)) {
      errors.name = 'There is already a collection with that name'
    }

    if (!currentCollection.name) {
      errors.name = "Name can't be empty"
    }

    return Object.keys(errors).length ? errors : null
  }

  const ref = useRef()

  useEffect(() => {
    setErrors(getErrors())
  }, [currentCollection])

  useEffect(() => {
    setCurrentCollection(collection)
  }, [collection])

  const hasChanges = !isDeepEquals(collection, currentCollection)
  const isNew = collection.isNew
  const hasErrors = errors !== null
  const { name } = currentCollection
  const isInitiallyWithoutComponents = components.filter((c) => c.collectionId === collection.id).length === 0

  const onNameChange = (name: string) => setCurrentCollection(currentCollection.change({ name }))
  const onDiscard = () => setCurrentCollection(collection)
  const onSave = () => currentCollection.save()
  const onDelete = () => collection.delete()
  const onCreateComponent = () => {
    const newComponent = collection.components.add({
      name: ''
    })
    components.add(newComponent)
    navigate(newComponent.getPath())
  }

  const emptyRequiredFields = [{ title: 'Name', value: currentCollection.name }]
    .filter(({ title, value }) => !value)
    .map(({ title }) => title)

  let hideAddComponentButton =
    hasChanges || isNew || query.datasourceId || query.status || componentId || collection.id === collectionId

  return (
    <EntityProvider
      isBordered={false}
      id={collection.id}
      emptyRequiredFields={emptyRequiredFields}
      invalidNonEmptyFields={[]}
      onDelete={!collection.isDefault && onDelete}
      hasChanges={hasChanges}
      onDiscard={onDiscard}
      onSave={onSave}
      isNonEditable={collection.isDefault}
      hasErrors={hasErrors}
      isNew={isNew}
      name='collection'
      mode='collection'
      ref={ref}
    >
      <Flex {...Details} slot='details'>
        <Text {...CollectionTitle}>
          {name} {collection.isDefault && <Badge colorScheme='gray'>Default</Badge>}
        </Text>
      </Flex>

      <Flex slot='extraActions' display={hideAddComponentButton ? 'none' : 'flex'}>
        <Button
          className='add-component-top'
          isDisabled={hideAddComponentButton}
          variant='primary'
          size='sm'
          mr='2'
          onClick={onCreateComponent}
        >
          Add component
        </Button>
      </Flex>

      <Box slot='form'>
        <FlexGrid>
          <FieldsetField {...Field} label='Name' isChanged={hasChanges}>
            <TextField error={errors?.name} onChange={onNameChange} value={name} />
          </FieldsetField>
        </FlexGrid>
      </Box>

      {isInitiallyWithoutComponents && (
        <EmptyContent
          className='no-components-collection'
          {...NoComponents}
          title='0 components'
          subtitle={
            <Button
              className='add-component-in-empty'
              isDisabled={hasChanges || isNew}
              onClick={onCreateComponent}
              variant='primary'
              size='sm'
            >
              Add component
            </Button>
          }
        />
      )}

      <EntitiesProvider
        url={`${library.getPath()}/collections`}
        activeEntityId={componentId}
        pre={`${collection.id}/components`}
        ids={componentIds}
      >
        <Grid
          display='grid'
          gap='24px'
          gridTemplateColumns={'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'}
          sx={{
            '@media (max-width: 1100px)': {
              gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
            }
          }}
          mt='5'
        >
          {componentIds.map((id) => (
            <GridItem colSpan={1} key={id}>
              <LibraryCollectionComponent key={id} id={id} />
            </GridItem>
          ))}
        </Grid>
      </EntitiesProvider>
    </EntityProvider>
  )
}

export default LibraryCollection

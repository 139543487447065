import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Text,
  Wrap
} from '@chakra-ui/react'
import { PageContainer, PageDescription, PageTitle } from '../components/styled.js'
import React from 'react'
import { useSDK } from '../exports.js'
import PluginsRegistration from '../components/plugins/PluginsRegistration.js'
import { useParams } from 'react-router-dom'
import PluginCard from '../components/plugins/PluginCard.js'
import PluginPage from '../components/plugins/PluginPage.js'
import { ExternalComponentModel, RenderingHostModel } from '@sitecore-feaas/sdk'

const Plugins = () => {
  const { componentId } = useParams()
  const renderingHost = useSDK('renderingHost')
  const availableComponents = useSDK('renderingHost.availableComponents')
  const registeredComponents = useSDK('renderingHost.registeredComponents')
  const allComponents = [
    ...registeredComponents,
    ...availableComponents.filter((a) => !registeredComponents.find((r) => r.id === a.id))
  ]
  const component = allComponents.find((c) => c.id === componentId)
  const loading =
    !renderingHost.connectionStatus ||
    renderingHost.connectionStatus === 'loading' ||
    !renderingHost.pluginsHostConnectionStatus ||
    renderingHost.pluginsHostConnectionStatus === 'loading'

  return (
    <>
      {(!renderingHost.pluginsHostConnectionStatus || renderingHost.pluginsHostConnectionStatus === 'loading') && (
        <PluginsRegistration />
      )}

      <Container {...PageContainer}>
        <Flex {...PageDescription}>
          <Text {...PageTitle}>{component?.title || 'Plugins'}</Text>
        </Flex>
        <Spacer mb={8} />
        {loading ? (
          <HStack>
            <Spinner boxSize='18px' speed='1.5s' alignSelf={'center'} justifySelf='center' />
            <Text color={'blackAlpha.500'}>Loading plugins</Text>
          </HStack>
        ) : renderingHost.connectionStatus === 'error' ? (
          <Alert status={'warning'}>
            <AlertIcon />
            <AlertTitle>Could not connect to rendering host</AlertTitle>
          </Alert>
        ) : renderingHost.pluginsHostConnectionStatus === 'error' ? (
          <Alert status={'warning'}>
            <AlertIcon />
            <AlertTitle>Could not connect to marketplace</AlertTitle>
          </Alert>
        ) : component ? (
          <PluginPage component={component} />
        ) : (
          <>
            {RenderingHostModel.getComponentsGroups(allComponents).map((group, index) => {
              const plugins = RenderingHostModel.getComponentsByGroup(allComponents, group)
              return (
                <Box key={index} my={10}>
                  <Text fontSize={24} fontWeight={600} my={4}>
                    {group || 'Ungrouped'} ({plugins.length})
                  </Text>
                  <Wrap spacing='30px'>
                    {plugins.map((c: ExternalComponentModel) => (
                      <PluginCard key={c.id} component={c} />
                    ))}
                  </Wrap>
                </Box>
              )
            })}
          </>
        )}
      </Container>
    </>
  )
}

export default Plugins

import { Box, Button, Flex, Grid, Text, VStack, Icon } from '@chakra-ui/react'
import { Style, StylesheetModel, isDeepEquals } from '@sitecore-feaas/sdk'
import * as inflection from 'inflection'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { mdiArrowLeft, mdiPlus } from '@mdi/js'
import { EntitiesContext } from '../../../contexts/EntitiesContext.js'
import { useLibrary } from '../../../hooks/useData.js'
import EntityProvider from '../../entities/EntityProvider.js'
import { LargeTitle, Title } from '../../styled.js'
import FontVariants from '../FontVariants.js'
import StyleCSSReference from '../StyleCSSReference.js'
import DetailsFieldset from '../fieldsets/DetailsFieldset.js'
import Preview from '../previews/index.js'
import StyleForm from './StyleForm.js'
import StyleSelect from './StyleSelect.js'
import StyleTheme from './StyleTheme.js'
import useWindowQueryUpdate from '../../../hooks/useWindowQueryUpdate.js'
import OverrideFieldset from '../fieldsets/OverrideFieldset.js'
import StyleFontSummary from './StyleFontSummary.js'

const StyleComponent = ({
  rule,
  rules,
  index,
  childrenRules,
  stylesheet
}: {
  rules: Style.Rule[]
  rule: Style.Rule
  stylesheet: StylesheetModel
  index: number
  childrenRules?: any
}) => {
  const library = useLibrary()

  const navigate = useNavigate()
  const { collection: activeCollection = 'basics', type: activeStyleType = 'font', styleId } = useParams()
  const [{ origin }] = useWindowQueryUpdate()

  const { editedEntityId } = useContext(EntitiesContext)

  const [errors, setErrors] = useState<Style.RuleError>(null)
  const [currentRule, setCurrentRule] = useState(rule)
  const [customRules, setCustomRules] = useState(getSavedCustomStyles)
  const [showStyleCSSReference, setShowStyleCSSReference] = useState(false)
  /** The aspect currently open in the selection screen inside the modal */
  const [editSelectionAspect, setEditSelectionAspect] = useState<Style.Type.ElementAspect | ''>('')
  /** The selected ids in the selection screen inside the modal */
  const [editedSelectedIds, setEditedSelectedIds] = useState([])
  /** Used to scroll to the custom rule when clicking the edit button from the custom rule */
  const [scrollToCustomRule, setScrollToCustomRule] = useState(false)
  /** The edited custom rule in the selection screen inside the modal */
  const [editedCustomRule, setEditedCustomRule] = useState<Style.Rule>(null)

  const {
    details: { id, title }
  } = rule
  const {
    details: { title: currentTitle, description: currentDescription }
  } = currentRule
  const isActive = styleId === id
  const hasChanges =
    !isDeepEquals(rule, currentRule) ||
    (rule.type === 'collection' ? false : !isDeepEquals(getSavedCustomStyles(), customRules)) ||
    (!!editSelectionAspect &&
      (editedSelectedIds !== (currentRule.props as any)[Style.TypeDefinitions[editSelectionAspect].property] ||
        !isDeepEquals(editedCustomRule, customRules[editSelectionAspect])))

  /* if isNew is set, closing card with discard style:
   - origin is GET parameter set when cloning a rule
   - rule without title is not valid
  */
  const isNew = isActive && (!Style.Rule.isValid(rule) || Boolean(origin))
  const isFirstTheme = rule.type === 'theme' && index === 0
  const isCollection = currentRule.type === 'collection'
  const isThemeCollection = isCollection && id === 'theme'
  const isFont = currentRule.type === 'font'
  const isCollectionLike = currentRule.type === 'collection' || currentRule.type === 'font'
  const computedIndex = () => (isNew || rule.type === 'collection' ? 0 : 1)

  const [tabIndex, setTabIndex] = useState(computedIndex())

  useEffect(() => {
    setTabIndex(computedIndex())
  }, [id])

  useEffect(() => {
    if (isActive) return
    // Reset edit aspect and tab index when modal closes
    setEditSelectionAspect('')
    setTabIndex(computedIndex())
    setScrollToCustomRule(false)
  }, [isActive])

  useEffect(() => {
    if (!currentRule.details.title) {
      setTabIndex(0)
    }
  }, [currentRule.details.title])

  const ref = useRef(null)

  useEffect(() => {
    onChange(rule)
  }, [rule])

  // reinitialize styles. Happens on save/discard/start
  const reset = () => {
    setCurrentRule(rules.find((s) => s.details.id == rule.details.id))
    setCustomRules(getSavedCustomStyles)
    if (activeStyleType === 'text' || activeStyleType === 'inline') {
      addCustom('palette')
    }
  }

  useEffect(reset, [])

  const onChange = (rule: Style.Rule) => {
    setCurrentRule(rule)
    setErrors(stylesheet.getRuleErrors(rule))
  }

  const onCopy = () => {
    const copy = stylesheet.duplicateRule(rule)
    navigate(
      `${library.getPath()}/styles/${activeCollection}/${activeStyleType}/${copy.details.id}?origin=${rule.details.id}`
    )
  }

  const onDiscard = () => reset()

  const onSave = () => {
    stylesheet.upsertRule(currentRule)
    const savedCustomStyles = getSavedCustomStyles()
    Object.keys(customRules).forEach((key: Style.Type.ElementAspect) => {
      let custom = customRules[key]

      if (custom === null) {
        if (savedCustomStyles[key]) {
          stylesheet.deleteRule(savedCustomStyles[key])
        }
      } else {
        stylesheet.upsertRule(custom)
      }
    })
    stylesheet.saveDraft()
    if (origin) {
      navigate(`${library.getPath()}/styles/${activeCollection}/${activeStyleType}/${styleId}`)
    }
  }

  const onDelete = () => {
    stylesheet.deleteRule(currentRule)
    stylesheet.saveDraft()
  }

  function getSavedCustomStyles(): Style.AspectByType {
    const result = {} as Style.AspectByType
    if (activeCollection !== 'elements' || rule.type === 'collection') return result

    return Style.getAspects(rule.type).reduce((savedCustomStyles, type) => {
      return Object.assign(savedCustomStyles, {
        [type]: rules.find((style) => style.details.elementId === currentRule.details.id && type === style.type) || null
      })
    }, result)
  }

  const updateSelection = (prop: string, value: unknown) => {
    setCurrentRule(
      (oldItem: Style.Rule) =>
        ({
          ...oldItem,
          props: { ...oldItem.props, [prop]: value }
        } as Style.Rule)
    )
  }

  const onCustomChange = (customStyle: Style.Rule, type: Style.Type.Aspect) => {
    setCustomRules((oldStyles) => ({
      ...oldStyles,
      [type]: customStyle
    }))
  }

  const addCustom = (type: Style.Type.ElementAspect) => {
    setCustomRules((customStyles) => {
      if (customStyles[type] != null) return customStyles
      return {
        ...customStyles,
        [type]: Style.Rule({
          type: type,
          details: {
            title: 'Custom',
            slug: 'custom',
            elementId: currentRule.details.id
          }
        })
      }
    })
  }

  const onToggleCustom = (type: Style.Type.ElementAspect) => {
    if (editedCustomRule) {
      const removedRuleId = editedCustomRule.details.id
      setEditedSelectedIds((prevEditedSelectedIds) => prevEditedSelectedIds.filter((id) => removedRuleId !== id))
      setCustomRules((prevCustomRules) => ({ ...prevCustomRules, [type]: null }))
      setEditedCustomRule(null)
      setScrollToCustomRule(false)
      return
    }

    setScrollToCustomRule(true)
    const customRule = Style.Rule({
      type: type,
      details: {
        title: 'Custom',
        slug: 'custom',
        elementId: currentRule.details.id
      }
    })
    setEditedCustomRule(customRule)
    setEditedSelectedIds((prevEditedSelectedIds) => [...prevEditedSelectedIds, customRule.details.id])
  }

  const forms = (function () {
    if (activeCollection === 'concepts') return

    const detailsForm = {
      ...Style.DetailsForm,
      fieldset: DetailsFieldset
    } as Style.Form

    const overrideForm = {
      ...Style.OverrideForm,
      fieldset: OverrideFieldset
    } as Style.Form

    if (Style.Rule.isInCategory(rule, 'basic')) {
      return [detailsForm, ...Style.getForms(rule.type)]
    }
    if (Style.Rule.isInCategory(rule, 'aspect')) {
      return [detailsForm, ...Style.getForms(rule.type), overrideForm]
    }

    const aspectForms: Style.Form[] = []

    Style.getAspects(rule.type).map((type) => {
      const { label, property, forms } = Style.TypeDefinitions[type]
      const selectedIds = (currentRule.props as any)[property]
      aspectForms.push({
        label,
        id: null,
        extractProperties: (props: any) => props[property] || null,
        validate: () => true,
        fieldset: (
          <StyleSelect
            rules={rules}
            rule={getSavedCustomStyles()[type] || rule}
            label={label}
            customRule={customRules[type]}
            type={type}
            options={rules.filter(
              (style) =>
                style.type === type &&
                !style.details.isInternal &&
                !style.details.elementId &&
                selectedIds.includes(style.details.id) // Pass only selected rules
            )}
            selectedIds={selectedIds}
            onChange={(newIds) => updateSelection(property, newIds)}
            onToggleCustom={() => onToggleCustom(type)}
            onChangeCustom={(customStyle) => onCustomChange(customStyle, type)}
            currentRule={currentRule}
            onEditSelectionClick={() => {
              setEditedSelectedIds(selectedIds)
              setEditedCustomRule(customRules[type])
              setEditSelectionAspect(type)
            }}
            onCustomRuleEdit={() => {
              setEditedSelectedIds(selectedIds)
              setEditedCustomRule(customRules[type])
              setEditSelectionAspect(type)
              setScrollToCustomRule(true)
            }}
          />
        )
      })
    })

    return [detailsForm, ...aspectForms, overrideForm]
  })()

  const onAddStyleToCollection = () => {
    const newRule = stylesheet.upsertRule(Style.Rule.forCollection(rule as Style.Rule<'collection'>))
    navigate(`${library.getPath()}/styles/${activeCollection}/${activeStyleType}/${newRule.details.id}`)
  }

  const emptyRequiredFields = [{ title: 'Name', value: currentRule.details.title }]
    .filter(({ title, value }) => !value)
    .map(({ title }) => title)

  const getName = () => {
    if (['inline', 'text', 'block'].includes(rule.type))
      return rules.find((s) => s.details.id === rule.details.collectionId).details.title

    return rule.type
  }

  // dont allow deleting custom element types without deleting styles first
  const isCustomElementWithStyles =
    rule.type == 'collection' &&
    Style.isTypeInCategory(rule.props.type, 'element') &&
    Style.Set.filterByCollectionId(rules, rule.details.id).length > 0

  const ModalHeader = () => {
    const header = title || `New ${inflection.singularize(getName()).toLowerCase()}`
    if (editSelectionAspect)
      return (
        <Button
          size='sm'
          pl={1}
          ml={-2}
          leftIcon={
            <Icon boxSize={22}>
              <path d={mdiArrowLeft} />
            </Icon>
          }
          onClick={() => {
            setScrollToCustomRule(false)
            setEditSelectionAspect('')
          }}
        >
          Back to {header}
        </Button>
      )
    return (
      <Text fontSize='md' lineHeight={8}>
        {header}
      </Text>
    )
  }

  const onTabChange = (index: number) => {
    if (forms[index].label !== 'Details' && !currentRule.details.title) return

    setTabIndex(index)
  }

  return (
    <>
      <EntityProvider
        id={id}
        onDelete={!rule.details.isInternal && !isFirstTheme && !isCustomElementWithStyles && onDelete}
        hasChanges={hasChanges}
        onDiscard={onDiscard}
        isBordered={!isCollectionLike}
        onSave={onSave}
        emptyRequiredFields={emptyRequiredFields}
        invalidNonEmptyFields={[]}
        hasErrors={!!errors}
        isNonEditable={rule.details.isInternal}
        isNew={isNew}
        name={getName()}
        modalHeader={<ModalHeader />}
        modalFooter={
          editSelectionAspect && (
            <Button
              size='sm'
              variant='primary'
              isDisabled={!hasChanges}
              aria-label='Save selected styles'
              onClick={() => {
                updateSelection(Style.TypeDefinitions[editSelectionAspect].property, editedSelectedIds)
                setCustomRules((prevCustomRules) => ({ ...prevCustomRules, [editSelectionAspect]: editedCustomRule }))
                setScrollToCustomRule(false)
                setEditSelectionAspect('')
              }}
            >
              Add
            </Button>
          )
        }
        type={rule.type}
        ref={ref}
        withModal
        mode={isCollectionLike ? 'collection' : 'block'}
        onCopy={!isCollectionLike && onCopy}
        onMouseEnterUpper={() => setShowStyleCSSReference(true)}
        onMouseLeaveUpper={() => setShowStyleCSSReference(false)}
      >
        {!isCollectionLike && (
          <Preview
            slot='preview'
            rule={currentRule}
            rules={Style.Set.join(
              rules,
              Object.keys(customRules)
                .map((key: Style.Type.ElementAspect) => customRules[key])
                .filter((rule) => rule)
            )}
          />
        )}

        {!isCollectionLike && (
          <Flex slot='details' w='full'>
            <VStack spacing={-1} alignItems='start' maxW='full'>
              <Flex {...Title} h={8} alignItems='center'>
                {title || <Box color='gray.400'>New {rule.type}</Box>}
              </Flex>
              <StyleFontSummary rule={rule} rules={rules} />
            </VStack>

            {showStyleCSSReference && !isActive && !isNew && (
              <Box position='absolute' right={7} mr={2} mt={1}>
                <StyleCSSReference rule={currentRule} variant='button' rules={rules} />
              </Box>
            )}
          </Flex>
        )}

        {isFont && (
          <Flex alignItems='center' slot='details'>
            <Text {...LargeTitle}>{currentTitle || `New ${rule.type}`}</Text>

            {currentRule.props.familyName && (
              <Text {...LargeTitle} ml='1'>
                ({currentRule.props?.variants.length})
              </Text>
            )}
          </Flex>
        )}

        {isCollection && !isThemeCollection && (
          <Box slot='details'>
            <Text {...LargeTitle}>{currentTitle || 'New collection'}</Text>

            {currentDescription && (
              <Text fontWeight='medium' color='blackAlpha.800'>
                {currentDescription}
              </Text>
            )}
          </Box>
        )}

        {activeCollection === 'concepts' && currentRule.type == 'theme' && rule.type == 'theme' && (
          <Box slot='form'>
            <StyleTheme
              currentRule={currentRule}
              isNew={isNew}
              rules={rules}
              rule={rule}
              onChange={onChange}
              errors={errors as Style.RuleError<Style.Rule<'theme'>>}
            />
          </Box>
        )}

        {activeCollection !== 'concepts' && !editSelectionAspect && (
          <Box slot='form'>
            {!isCollectionLike && (
              <Box maxWidth='344px' mb={5}>
                <Preview
                  rule={currentRule}
                  rules={Style.Set.join(
                    rules,
                    Object.keys(customRules)
                      .map((key: Style.Type.ElementAspect) => customRules[key])
                      .filter((rule) => rule)
                  )}
                />
              </Box>
            )}

            <StyleForm
              mode={
                rule.type === 'font' || rule.type === 'collection' || activeCollection === 'basics' ? 'plain' : 'tabs'
              }
              rules={rules}
              rule={rule}
              currentRule={currentRule}
              forms={forms}
              errors={errors}
              activeIds={[styleId]}
              isNew={isNew}
              onChange={onChange}
              onTabChange={onTabChange}
              tabIndex={tabIndex}
            />

            {!isFont && activeCollection === 'basics' && <StyleCSSReference rule={currentRule} rules={rules} />}
          </Box>
        )}

        {editSelectionAspect && (
          <Box slot='form'>
            <Text textTransform='capitalize' fontWeight='semibold' color='blackAlpha.500' mb={5}>
              {inflection.pluralize(editSelectionAspect)}
            </Text>
            <StyleSelect
              rules={rules}
              rule={getSavedCustomStyles()[editSelectionAspect]}
              label={Style.TypeDefinitions[editSelectionAspect].label}
              customRule={editedCustomRule}
              type={editSelectionAspect}
              options={rules.filter(
                (style) => style.type === editSelectionAspect && !style.details.isInternal && !style.details.elementId
              )}
              selectedIds={editedSelectedIds}
              onChange={(newIds) => updateSelection(Style.TypeDefinitions[editSelectionAspect].property, newIds)}
              onToggleCustom={() => onToggleCustom(editSelectionAspect)}
              currentRule={currentRule}
              editSelection
              editSelectedIds={setEditedSelectedIds}
              scrollToCustomRule={scrollToCustomRule}
              customRuleForm={
                <StyleForm
                  rules={rules}
                  rule={getSavedCustomStyles()[editSelectionAspect] || rule}
                  isNew={isNew}
                  mode='tabs'
                  currentRule={editedCustomRule}
                  forms={Style.TypeDefinitions[editSelectionAspect].forms}
                  activeIds={[styleId]}
                  onChange={setEditedCustomRule}
                  errors={errors}
                />
              }
            />
          </Box>
        )}

        {isCollection && (
          <Grid mt={5} gap={4} templateColumns='repeat(auto-fill, minmax(220px, 1fr))' gridAutoRows='1fr'>
            {childrenRules}
            {isCollection && (
              <Button
                width='100%'
                height='100%'
                minHeight={110}
                borderRadius='md'
                slot='extraActions'
                isDisabled={!!editedEntityId}
                variant='outline'
                colorScheme='primary'
                borderStyle='dashed'
                size='sm'
                mr={!rule.details.isInternal && 3}
                onClick={onAddStyleToCollection}
                aria-label={
                  isCollection && activeCollection === 'elements'
                    ? `Add ${inflection.singularize(rule.details.title).toLowerCase()} style`
                    : `Add ${activeStyleType}`
                }
              >
                <Icon boxSize={10} backgroundColor='primary.500' color='white' borderRadius='full' p={2}>
                  <path d={mdiPlus} width='icon-xl' />
                </Icon>
              </Button>
            )}
          </Grid>
        )}

        {isFont && currentRule.props.variants.length > 0 && (
          <Box mt={5}>
            <FontVariants
              allVariants={currentRule.props.variants}
              exampleContent={currentRule.details.exampleContent}
              familyName={currentRule.props.familyName}
              platform={currentRule.props.platform}
              onClickVariant={() =>
                navigate(`${library.getPath()}/styles/${activeCollection}/${activeStyleType}/${currentRule.details.id}`)
              }
            />
          </Box>
        )}
      </EntityProvider>
    </>
  )
}

export default StyleComponent

import { Box, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import EntityProvider from '../entities/EntityProvider.js'

const SettingsCard = ({
  name,
  description,
  hasChanges = false,
  onDiscard = () => {},
  onSave = () => {},
  hasErrors = false,
  top,
  bottom,
  children
}: {
  name: string
  description?: string
  hasChanges?: boolean
  onDiscard?: () => void
  onSave?: () => void
  hasErrors?: boolean
  top?: ReactNode
  bottom?: ReactNode
  children: ReactNode
}) => {
  return (
    <>
      <EntityProvider
        id={name}
        name={name}
        hasChanges={hasChanges}
        onDiscard={onDiscard}
        onSave={onSave}
        hasErrors={hasErrors}
      >
        <Box slot='details' maxH='200px'>
          <Text fontSize={'x-large'} fontWeight={'600'} mr={3} mb={2} noOfLines={1}>
            {name}
          </Text>
          {description && (
            <Text color={'blackAlpha.500'} fontSize={'md'} mr={3} mb={1} noOfLines={1}>
              {description}
            </Text>
          )}
          {top && <Box>{top}</Box>}
        </Box>
        <Box slot='form'>{children}</Box>
        {bottom && <Box>{bottom}</Box>}
      </EntityProvider>
    </>
  )
}

export default SettingsCard

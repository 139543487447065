import {
  Alert,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Code,
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useToast,
  VStack
} from '@chakra-ui/react'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
import React, { useState } from 'react'
import { useLibrary, useSDK } from '../../hooks/useData.js'
import FieldsetField from '../FieldsetField.js'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'
import { mdiAutoFix, mdiCameraEnhance, mdiCheckboxMarked, mdiContentCopy, mdiMagicStaff, mdiOpenInNew } from '@mdi/js'
import copy from 'copy-to-clipboard'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'

import 'react-syntax-highlighter/dist/cjs/languages/prism/jsx.js'
import 'react-syntax-highlighter/dist/cjs/languages/prism/bash.js'
import SettingsCard from './SettingsCard.js'
import { Link } from '@chakra-ui/react'

const XMC_STEPS = [
  {
    label: 'Ensure that your JSS app is up to date',
    description: (
      <>
        We recommend that you{' '}
        <Link
          color='primary.700'
          textDecoration={'underline'}
          href='https://doc.sitecore.com/xmc/en/developers/jss/216/jss-xmc/upgrade-guide-for-jss-21-5-next-js-apps-to-version-21-6.html'
        >
          upgrade your JSS app
        </Link>
        . If you can't upgrade yet, make sure you have the latest patch version by running
      </>
    ),
    code: `npm upgrade @sitecore-jss/sitecore-jss-react`
  },
  {
    label: 'Scaffold a new BYOC component in your JSS app',
    description:
      'To scaffold a new component in the JSS app, run the following command in your terminal, replacing ByocComponentName with the actual name of the component.',
    code: 'jss scaffold ByocComponentName --byoc'
  },
  {
    label: 'Import a BYOC component',
    description: (
      <>
        Add an import of your component to <Code>byoc/index.hybrid.ts</Code> for dynamic components that will be
        pre-rendered on the server or <Code>byoc/index.client.ts</Code> for client-side only components.
      </>
    ),
    code: `import 'src/components/ByocComponentName';`
  },
  {
    label: 'Deploy the application',
    description: 'Finish the process by deploying your application to XM Cloud.'
  }
]
const REACT_STEPS = [
  {
    label: 'Install the Components SDK',
    description: 'Run the following command in your terminal to install the Components SDK. ',
    code: 'npm install @sitecore-feaas/clientside'
  },
  {
    label: 'Import Components SDK',
    description: 'Paste this line into the top of your external component code to import the Components SDK.',
    code: '/* eg. src/components/MyComponent.js */\n\n' + 'import * as FEAAS from "@sitecore-feaas/clientside/react"\n'
  },
  {
    label: 'Define code component',
    description:
      "In the external component code, register each component using registerComponent and specifying its parameters. When a component is registered, it's visible in the Components library, and a content author can enter its input parameters in Pages.",
    code:
      '/* eg. src/components/MyComponent.js */\n\n' +
      'export default function MyComponent(props) {\n' +
      '  /*your component body*/\n' +
      '}\n\n' +
      'FEAAS.registerComponent(MyComponent, {\n' +
      "    name: 'MyComponent',\n" +
      "    description: 'Description of MyComponent.',\n" +
      '    required: [ /* List of properties that are required */ ],\n' +
      '    properties: {\n' +
      '        /* Your component props in JSON schema format\n' +
      '           Read more: https://json-schema.org/learn/getting-started-step-by-step#add-the-properties-object */\n' +
      '    },\n' +
      '    ui: {\n' +
      '        /* Any ui configuration for the inputs form rendered in the builder\n' +
      '           Read more: https://rjsf-team.github.io/react-jsonschema-form/docs/api-reference/uiSchema/ */\n' +
      '    }\n' +
      '})\n'
  },
  {
    label: 'Create a preview rendering host',
    description: `Use the following code as a starting point for creating a dedicated rendering host that allows you to preview the external component after adding it to the builder.`,
    code:
      '/* eg. src/App.js */\n\n' +
      '/* Example React App for rendering the component in a /preview route using React Router */\n' +
      'import { Routes, Route } from "react-router-dom";\n' +
      'import * as FEAAS from "@sitecore-feaas/clientside/react"\n' +
      '/**\n' +
      ' *  Make sure that you import your registered components in the rendering host to be able to render them \n' +
      ' *  Warning: some bundlers optimize out unused imports resulting to your component not been included in the bundle\n' +
      ' *  For more information handling this case, at the nextjs example repo: https://bitbucket.org/stylelabsdev/feaas-nextjs-example\n' +
      ' */\n' +
      'import "./components/MyComponent"\n' +
      '\n' +
      'function App() {\n' +
      '    return (\n' +
      '        <Routes>\n' +
      '            <Route path={/* your rendering host path here (eg./preview)*/} element={<FEAAS.External.Preview />} />\n' +
      '        </Routes>\n' +
      '    )\n' +
      '}\n' +
      '\n' +
      'export default App;'
  }
]

const RenderingHost = () => {
  const registeredComponents = useSDK('renderingHost.registeredComponents')
  const registeredDatasources = useSDK('renderingHost.registeredDatasources')
  const library = useLibrary()
  const renderingHost = useSDK('renderingHost')
  const toast = useToast()
  const [framework, setFramework] = useState(0)
  const [externalPreviewURL, setExternalPreviewURL] = useState<string>(library.settings.externalPreviewURL)

  const eraseExternalComponents = () => {
    registeredComponents.setItems([])
    registeredDatasources.setItems([])
  }

  return (
    <>
      <SettingsCard
        name={'Rendering host'}
        description={
          'Use the rendering host to register your own code components and make them available in the Component builder.'
        }
        hasChanges={externalPreviewURL !== library.settings.externalPreviewURL}
        onDiscard={() => {
          setExternalPreviewURL(library.settings.externalPreviewURL)
        }}
        onSave={() => {
          eraseExternalComponents()
          library.save({ settings: { ...library.settings, externalPreviewURL } })
        }}
        top={
          renderingHost.connectionStatus === 'loading' ? (
            <HStack>
              <Spinner boxSize='18px' speed='1.5s' alignSelf={'center'} justifySelf='center' />
              <Text color={'blackAlpha.500'}>Connecting to rendering host</Text>
            </HStack>
          ) : renderingHost.connectionStatus === 'error' ? (
            <Alert status={'warning'}>
              <AlertIcon />
              <AlertTitle>Could not connect to rendering host</AlertTitle>
            </Alert>
          ) : (
            <>
              <Badge colorScheme={!registeredComponents.length ? 'blackAlpha' : 'teal'} mr='2' px={2} fontSize={'md'}>
                {registeredComponents.length || 'No'} registered component{registeredComponents.length !== 1 ? 's' : ''}
              </Badge>
              {registeredDatasources.length > 0 && (
                <Badge
                  colorScheme={!registeredDatasources.length ? 'blackAlpha' : 'teal'}
                  mr='2'
                  px={2}
                  fontSize={'md'}
                >
                  {registeredDatasources.length || 'No'} registered datasource
                  {registeredDatasources.length !== 1 ? 's' : ''}
                </Badge>
              )}
            </>
          )
        }
      >
        <Box mt={5}>
          <VStack alignItems={'left'}>
            <FieldsetField extraProps={{ width: '400px' }} label='Set your rendering host'>
              <HStack>
                <Input
                  value={externalPreviewURL}
                  placeholder={library.getPreviewURL() ?? 'Enter iframe URL'}
                  onChange={(e) => {
                    setExternalPreviewURL(e.target.value)
                  }}
                />
              </HStack>
            </FieldsetField>
            {library.getPreviewURL() && !externalPreviewURL && (
              <Text variant={'small'}>
                <Icon fontSize={'xl'} verticalAlign={'top'}>
                  <path d={mdiAutoFix} />
                </Icon>{' '}
                Automatically linked to the deployed JSS application
              </Text>
            )}
          </VStack>

          <Tabs index={framework} onChange={setFramework} mt={8}>
            <TabList>
              <Tab>XMCloud</Tab>
              <Tab>React</Tab>
              <Tooltip label={'Coming soon'}>
                <Tab isDisabled>Vue</Tab>
              </Tooltip>
            </TabList>
          </Tabs>
          {(framework == 0 ? XMC_STEPS : REACT_STEPS).map((step) => (
            <VStack alignItems={'left'} mt={6} key={step.label}>
              <Text fontWeight={600} color={'blackAlpha.800'}>
                {step.label}
              </Text>
              <Text color={'blackAlpha.600'}>{step.description}</Text>
              <Box>
                {step.code && (
                  <HStack
                    backgroundColor={dark['pre[class*="language-"]'].background}
                    borderRadius={'4px'}
                    padding={3}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                  >
                    <>
                      <SyntaxHighlighter
                        id='generated-code'
                        language={step.code.startsWith('npm') || step.code.startsWith('jss') ? 'bash' : 'jsx'}
                        style={dark}
                        customStyle={{
                          borderRadius: '4px',
                          fontSize: '14px',
                          flexShrink: '1',
                          overflow: 'auto',
                          margin: '0'
                        }}
                      >
                        {step.code}
                      </SyntaxHighlighter>
                      <Button
                        variant={'primary'}
                        onClick={() => {
                          copy(step.code)
                          toast({
                            duration: 4000,
                            status: 'success',
                            title: 'Code snippet is copied'
                          })
                        }}
                        size={'xs'}
                      >
                        <HStack>
                          <Icon boxSize='icon-md'>
                            <path d={mdiContentCopy} />
                          </Icon>
                          <Text ml={2} alignSelf={'flex-end'}>
                            Copy
                          </Text>
                        </HStack>
                      </Button>
                    </>
                  </HStack>
                )}
              </Box>
            </VStack>
          ))}
          <Divider mt='6' mb='3' />
          <Box mt={4}>
            <Text ml={1}>
              Learn more about registering React components, including server-side generation, user-configurable
              properties, and other topics
            </Text>
            <Button
              as={'a'}
              variant={'secondary'}
              size={'xs'}
              target={'_blank'}
              mt={2}
              href={
                'https://doc.sitecore.com/xmc/en/developers/xm-cloud/walkthrough--registering-an-external-react-component.html'
              }
            >
              Registering external React component{' '}
              <Icon boxSize='icon-md' ml={2}>
                <path d={mdiOpenInNew} />
              </Icon>
            </Button>
          </Box>
        </Box>
      </SettingsCard>
    </>
  )
}

export default RenderingHost

import { useSDK } from '../../hooks/useData.js'
import { useEffect, useState } from 'react'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

const PluginPageVersions = ({ component }: { component: ExternalComponentModel }) => {
  const sdk = useSDK()
  const [versions, setVersions] = useState([])

  useEffect(() => {
    const url = component.links?.npm + '?activeTab=versions'
    if (url) {
      sdk
        .proxy(url, { method: 'GET' })
        .then((response) => response.text())
        .then((html) => {
          // parse versions from npm page
          const parser = new DOMParser()
          const doc = parser.parseFromString(html, 'text/html')
          const versionsElement = doc.querySelector('#tabpanel-versions > div > ul:nth-of-type(2)')
          if (versionsElement) {
            const listItems = Array.from(versionsElement.querySelectorAll('li'))
            const versions = listItems
              .map((li) => {
                const version = li.querySelector('a')?.innerText || ''
                const published = li.querySelector('time')?.innerText || ''
                return { version, published }
              })
              .filter((v) => v.version)
            setVersions(versions)
          } else {
            console.warn('Versions not found in HTML')
          }
        })
    }
  }, [])
  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th>Version</Th>
            <Th textAlign={'right'}>Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {versions.map((v, i) => (
            <Tr key={i}>
              <Td>{v.version}</Td>
              <Td textAlign={'right'}>{v.published}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default PluginPageVersions

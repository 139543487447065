import React, { useEffect, useState } from 'react'
import { Tag } from '@chakra-ui/react'
import type { Component } from '@sitecore-feaas/sdk'
import * as inflection from 'inflection'

const StatusBadge = (props: { status: Component['status'] }) => {
  const { status } = props

  const [colorScheme, setColorScheme] = useState<string>('')

  useEffect(() => {
    switch (status) {
      case 'published':
        setColorScheme('green')
        break
      case 'staged':
        setColorScheme('cyan')
        break
      case 'deleted':
        setColorScheme('red')
        break
      case 'draft':
        setColorScheme('blue')
        break
      case 'changed':
        setColorScheme('yellow')
        break
    }
  }, [status])

  return (
    colorScheme && (
      <Tag flexShrink={0} colorScheme={colorScheme}>
        {inflection.titleize(status)}
      </Tag>
    )
  )
}

export default StatusBadge

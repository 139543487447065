import { PageContainer, PageDescription, PageTitle } from '../components/styled.js'
import { Box, Container, Flex, Text, useToast } from '@chakra-ui/react'
import { useLibrary, useSDK } from '../hooks/useData.js'
import EntitiesProvider from '../components/entities/EntitiesProvider.js'
import { useParams } from 'react-router-dom'
import MainSetting from '../components/settings/MainSetting.js'
import RenderingHost from '../components/settings/RenderingHost.js'
import React, { useEffect } from 'react'
import ComponentsCollections from '../components/settings/ComponentsCollections.js'
import StyleLibraries from '../components/settings/StyleLibraries.js'
import { CollectionModel, StylesheetModel } from '@sitecore-feaas/sdk'
import LibrarySharing from '../components/settings/LibrarySharing.js'
import ContentHubOne from '../components/settings/ContentHubOne.js'
import ApiKey from '../components/settings/ApiKey.js'

const LibrarySettings = () => {
  const library = useLibrary()
  const sdk = useSDK()
  const { settingId } = useParams()
  const toast = useToast()
  useEffect(() => {
    ;(async () => {
      const libraries = (await sdk.TemplateLibraries.fetch(library)).map((lib) => new sdk.Library(lib))
      const promises: Promise<StylesheetModel | CollectionModel[]>[] = []
      libraries.forEach((lib) => {
        const { templates } = lib.settings as { templates: string[] }
        if (templates.includes('stylesheets') || templates.includes('collections')) {
          promises.push(lib.stylesheets.add({ status: 'saved' }).get())
        }
        if (templates.includes('collections')) {
          promises.push(lib.collections.fetch())
        }
      })
      try {
        const results = await Promise.all(promises)
        sdk.templateLibraries.setItems(libraries)
      } catch (e) {
        toast({
          isClosable: true,
          duration: 4000,
          status: 'error',
          title: 'Could not fetch template libraries',
          description: e.message
        })
      }
    })()
  }, [])
  return (
    <Container {...PageContainer}>
      <Flex {...PageDescription}>
        <Text {...PageTitle}>Settings</Text>
      </Flex>

      <EntitiesProvider url={`${library.getPath()}/settings`} activeEntityId={settingId} ids={['library', 'host']}>
        <>
          <Box mt={6} fontWeight={600}>
            Your component and style libraries
          </Box>
          <MainSetting />
          <LibrarySharing />
          <StyleLibraries />
          <ComponentsCollections />
          <Box mt={6} fontWeight={600}>
            Integrations
          </Box>
          <ApiKey />
          <RenderingHost />
          <ContentHubOne />
        </>
      </EntitiesProvider>
    </Container>
  )
}

export default LibrarySettings

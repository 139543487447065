import { Box, Button, Checkbox, Icon, Text } from '@chakra-ui/react'
import { ReactElement, useEffect } from 'react'
import { mdiMinus, mdiPlus } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'
import * as inflection from 'inflection'

import PaletteTextFieldset from '../fieldsets/PaletteTextFieldset.js'
import StyleSelectGrid from './StyleSelectGrid.js'
import EmptyContent from '../../EmptyContent.js'
import { scrollForTogglingCard } from '../../../utils/dom.js'

const StyleSelect = ({
  options,
  selectedIds,
  type,
  label,
  customRule,
  currentRule,
  rule,
  rules,
  editSelection = false,
  editSelectedIds,
  customRuleForm,
  scrollToCustomRule = false,
  onChange,
  onToggleCustom,
  onChangeCustom,
  onEditSelectionClick,
  onCustomRuleEdit
}: {
  options: Style.Rule[]
  selectedIds: Style.RuleId[]
  label: string
  customRule: Style.Rule
  rule: Style.Rule
  rules: Style.Rule[]
  type: Style.Rule['type']
  currentRule: Style.Rule
  editSelection?: boolean
  editSelectedIds?: (style: Style.RuleId[]) => void
  customRuleForm?: ReactElement
  scrollToCustomRule?: boolean
  onChange: (style: Style.RuleId[]) => void
  onToggleCustom: () => void
  onChangeCustom?: (custom: Style.Rule) => void
  onEditSelectionClick?: () => void
  onCustomRuleEdit?: () => void
}) => {
  const styles = [customRule, ...options].filter(Boolean)
  const stylesIds = styles.map((style) => style.details.id)
  const blankId = Style.Set.findBlankId(rules, type)

  const isEmpty = !selectedIds.length && !customRule

  // Account for blank rule (always available) and customRule (when enabled)
  const isAllSelected = selectedIds.length === options.length + (customRule ? 2 : 1)
  const isChecked = (id: Style.RuleId) => isAllSelected || selectedIds.includes(id)
  const isDefault = (id: Style.RuleId) => {
    // in "Allowed all", second option is default if available
    if (selectedIds.length === 0) return (stylesIds[1] || stylesIds[0]) === id
    return selectedIds[0] === id
  }

  const selectedIdsWithIdRemoved = (id: Style.RuleId) => selectedIds.filter((_id) => id && _id !== id)
  const selectedIdsWithIdAdded = (id: Style.RuleId) => [...selectedIds, id]

  const onCheck = (id: Style.RuleId) => {
    let ids = isChecked(id) ? selectedIdsWithIdRemoved(id) : selectedIdsWithIdAdded(id)
    editSelection ? editSelectedIds(ids) : onChange(ids)

    return ids
  }

  const onMakeDefault = (id: Style.RuleId) => {
    const ids = onCheck(id)
    onChange([id, ...ids.filter((idd) => idd !== id)])
  }
  const collectionIds = options.reduce(
    (prev, curr) => (prev.includes(curr.details.collectionId) ? prev : [...prev, curr.details.collectionId]),
    []
  )
  // collections are computed this way to have the same sorting as in the style view
  const collections = rules.filter((r) => collectionIds.includes(r.details.id))

  if (label === 'Text color') {
    return (
      customRule && (
        <PaletteTextFieldset
          onlyTextColor={currentRule.details.collectionId != 'link'}
          rule={rule as any}
          rules={rules}
          currentRule={customRule as Style.Rule<'palette'>}
          onChange={(style) => {
            onChange([style.details.id])
            onChangeCustom(style)
          }}
        />
      )
    )
  }

  useEffect(() => {
    if (scrollToCustomRule) {
      return scrollForTogglingCard(document.getElementById('style-select-custom-form'))
    }
  }, [scrollToCustomRule])

  return (
    <>
      {!editSelection && !isEmpty && (
        <Button position='absolute' size='sm' variant='secondary' right={0} top='19px' onClick={onEditSelectionClick}>
          <Icon mr={2} boxSize='icon-xl'>
            <path d={mdiPlus} />
          </Icon>
          Add {inflection.pluralize(label.toLowerCase())}
        </Button>
      )}

      {/* No styles selected message */}
      {!editSelection && isEmpty && (
        <EmptyContent
          state='add'
          title={`No ${inflection.pluralize(label.toLowerCase())} added`}
          subtitle={
            <Button size='sm' variant='secondary' onClick={onEditSelectionClick}>
              <Icon mr={2} boxSize='icon-xl'>
                <path d={mdiPlus} />
              </Icon>
              Add {inflection.pluralize(label.toLowerCase())}
            </Button>
          }
        />
      )}

      {editSelection && (
        <Checkbox
          position='absolute'
          right={6}
          top={3}
          isChecked={isAllSelected}
          onChange={(e) => editSelectedIds(e.target.checked ? [blankId, ...stylesIds] : [])}
        >
          Select all
        </Checkbox>
      )}

      {/* Inherit styles */}
      {(editSelection || selectedIds.includes(blankId) || (!selectedIds.length && isAllSelected)) && (
        <Box mb={7}>
          <StyleSelectGrid
            options={[Style.Set.findBlank(rules, type)]}
            rules={rules}
            onCheck={onCheck}
            isChecked={isChecked}
            isDefault={isDefault}
            isBlank
            onMakeDefault={onMakeDefault}
            editSelection={editSelection}
          />
        </Box>
      )}

      {/* grouped by collection styles */}
      {collections.map((collection) => {
        const collectionOptions = options.filter((o) => o.details.collectionId === collection.details.id)
        if (!collectionOptions.length) return
        return (
          <Box key={collection.details.id} mb={5}>
            <Text color='blackAlpha.800' fontWeight='medium' fontSize='md' mb={5}>
              {collection.details.title} ({collectionOptions.length})
            </Text>
            <StyleSelectGrid
              options={collectionOptions}
              rules={rules}
              onCheck={onCheck}
              isChecked={isChecked}
              isDefault={isDefault}
              onMakeDefault={onMakeDefault}
              editSelection={editSelection}
            />
          </Box>
        )
      })}

      {!editSelection && customRule && (
        <Box>
          <Text color='blackAlpha.800' fontWeight='medium' fontSize='md' mb={5}>
            Custom
          </Text>
          <StyleSelectGrid
            options={[customRule]}
            rules={rules}
            onCheck={onToggleCustom}
            isChecked={isChecked}
            isDefault={isDefault}
            onMakeDefault={onMakeDefault}
            editSelection={editSelection}
            onCustomRuleEdit={onCustomRuleEdit}
          />
        </Box>
      )}

      {editSelection && (
        <Button size='sm' variant='secondary' onClick={onToggleCustom}>
          <Icon mr={2} boxSize='icon-xl'>
            <path d={customRule ? mdiMinus : mdiPlus} />
          </Icon>
          {customRule ? 'Remove custom' : 'Create custom'}
        </Button>
      )}

      {editSelection && customRule && (
        <Box id='style-select-custom-form' mt='5'>
          {customRuleForm}
        </Box>
      )}
    </>
  )
}

export default StyleSelect

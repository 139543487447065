import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Text
} from '@chakra-ui/react'
import { ComponentModel, VersionModel } from '@sitecore-feaas/sdk'
import PublisherComponentsVersion from './PublisherComponentsVersion.js'
import { useMemo } from 'react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { ComponentsTargetStatus } from './PublisherComponents.js'

const PublisherComponentsComponent = ({
  versions,
  component,
  targetStatus,
  selectedVersionIds,
  onSelect,
  isExpanded
}: {
  versions: VersionModel[]
  component: ComponentModel
  targetStatus: ComponentsTargetStatus
  selectedVersionIds: string[]
  onSelect: (id: string[]) => void
  isExpanded: boolean
}) => {
  const versionIds = useMemo<string[]>(() => versions.map((v) => v.getId()), [versions])
  const componentVersions = useMemo<VersionModel[]>(
    () => component.versions.filter((v) => versionIds.includes(v.getId())),
    [component.versions, versionIds]
  )

  const selectedAll = componentVersions.every((ver) => selectedVersionIds.includes(ver?.getId()))
  const selectedSome = !selectedAll && componentVersions.some((ver) => selectedVersionIds.includes(ver?.getId()))
  const selectedNumber = componentVersions.filter((ver) => selectedVersionIds.includes(ver?.getId())).length

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const toBeAdded: string[] = []
      componentVersions.forEach((ver) => {
        if (!selectedVersionIds.includes(ver.id)) toBeAdded.push(ver.getId())
      })
      onSelect([...selectedVersionIds, ...toBeAdded])
    } else {
      const toBeRemoved: string[] = []
      componentVersions.forEach((ver) => {
        if (selectedVersionIds.includes(ver.getId())) toBeRemoved.push(ver.getId())
      })
      onSelect(selectedVersionIds.filter((id) => !toBeRemoved.includes(id)))
    }
  }

  return (
    <Box
      layerStyle='outline.interactive'
      my={3}
      borderRadius={4}
      className={'publisher-component'}
      border={0}
      outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
      outlineColor={
        selectedSome || selectedAll ? 'var(--chakra-colors-primary-500)' : 'var(--chakra-colors-blackAlpha-200)'
      }
      _hover={
        selectedSome || selectedAll
          ? { background: 'white', outlineColor: 'var(--chakra-colors-primary-500)' }
          : isExpanded
          ? { background: 'white', outlineColor: 'var(--chakra-colors-blackAlpha-400)' }
          : { background: 'blackAlpha.100', outlineColor: 'var(--chakra-colors-blackAlpha-400)' }
      }
    >
      <AccordionButton padding={6} _hover={isExpanded ? { background: 'white' } : { background: 'blackAlpha.100' }}>
        <Flex flex='1' textAlign='left' margin={3}>
          <Checkbox
            aria-label={'Select component'}
            colorScheme={'primary'}
            isIndeterminate={selectedSome}
            isChecked={selectedAll}
            onChange={handleCheck}
          >
            <Flex fontSize={'md'} fontWeight={500}>
              <Text color='gray.800'>Component: {component.name} </Text>
              <Text ml={2} color='primary.500'>
                ({selectedNumber} out of {componentVersions?.length} versions selected)
              </Text>
            </Flex>
          </Checkbox>
        </Flex>

        <IconButton
          icon={
            <Icon boxSize={'icon-lg'}>
              <path d={isExpanded ? mdiChevronUp : mdiChevronDown} />
            </Icon>
          }
          aria-label={'Expand Component'}
        ></IconButton>
      </AccordionButton>

      <AccordionPanel p={0} backgroundColor={'white'}>
        {isExpanded &&
          componentVersions.map((version) => (
            <Box mx={10} key={version.getId()}>
              <PublisherComponentsVersion
                version={version}
                targetStatus={targetStatus}
                selectedVersionIds={selectedVersionIds}
                onSelect={onSelect}
                isExpanded={isExpanded}
              />
            </Box>
          ))}
      </AccordionPanel>
    </Box>
  )
}

export default PublisherComponentsComponent

import { Icon, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { mdiClose, mdiMagnify } from '@mdi/js'
import { ChangeEvent, useContext } from 'react'
import { QueryStringContext } from './providers/QueryStringProvider.js'

const Search = ({className}: {className?: string}) => {
  const [query, setQuery] = useContext(QueryStringContext)

  return (
    <InputGroup className={className}>
      <InputLeftElement
        children={
          <Icon w='5' h='5' color='gray.600'>
            <path d={mdiMagnify} />
          </Icon>
        }
      />
      <Input
        placeholder='Search by name'
        value={query.search || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery({ search: e.target.value || null })}
      />
      {query.search?.length > 0 && (
        <InputRightElement>
          <Icon onClick={() => setQuery({ search: null })}>
            <path d={mdiClose} />
          </Icon>
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default Search

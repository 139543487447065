import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import 'virtual:vite-plugin-sentry/sentry-config'
import Canvas from './components/editor/canvas/Canvas.js'
import BasicLayout from './components/layout/BasicLayout.js'
import AppContext from './components/providers/ContextProvider.js'
import CollectionsRoute from './routes/CollectionsRoute.js'
import Datasources from './routes/Datasources.js'
import Editor from './routes/Editor.js'
import Publishing from './routes/Publishing.js'
import Styles from './routes/Styles.js'
import { ThemeTypings } from './theme/types.js'
import LibrarySettings from './routes/LibrarySettings.js'
import Picker from './components/picker/Picker.js'
import { getEnvironment } from './components/providers/EnvironmentProvider.js'
import { Tooltip } from '@chakra-ui/react'
import { CustomNavigateFunction, CustomNavigateOptions } from './hooks/useWindowQueryUpdate.js'
import { Appstrinsic } from './utils/gainsight.js'
import Plugins from './routes/Plugins.js'

//import 'virtual:vite-plugin-sentry/sentry-config'

declare module '@chakra-ui/styled-system' {
  export interface CustomThemeTypings extends ThemeTypings {}
}

//const dist = import.meta.env.PLUGIN_SENTRY_CONFIG.dist
//const release = import.meta.env.PLUGIN_SENTRY_CONFIG.release

const env = getEnvironment()
Sentry.init({
  dsn:
    env == 'localhost' || env == 'ci'
      ? null
      : 'https://7667cd76ec244288a0b13dda5f5412ca@o1409950.ingest.sentry.io/6746908',
  integrations: [new BrowserTracing()],
  //  dist,
  //  release,
  environment: env,
  tracesSampleRate: 1.0
})

export default function App() {
  console.log('Loading environment: ', env)

  return (
    <BrowserRouter>
      <AppBody>
        <BasicLayout>
          <Routes>
            {/* No component is rendered in /logout because logout is called inside APIProvider - never reaches this end */}
            <Route path='/logout' />

            <Route path='/' element={<CollectionsRoute key='collections' />} />

            <Route path='/libraries/:libraryId' element={<CollectionsRoute key='collections' />}>
              <Route path='collections' element={<CollectionsRoute key='collections' />}>
                <Route path=':collectionId' element={<CollectionsRoute key='collections' />}>
                  <Route path='components/:componentId' element={<CollectionsRoute key='collections' />} />
                </Route>
              </Route>
            </Route>

            <Route path='/libraries/:libraryId/datasources' element={<Datasources key='datasource' />}>
              <Route path=':datasourceId' element={<Datasources key='datasource' />} />
            </Route>

            <Route path='/libraries/:libraryId/styles' element={<Styles key='styles' />}>
              <Route path=':collection' element={<Styles key='styles' />}>
                <Route path=':type' element={<Styles key='styles' />}>
                  <Route path=':styleId' element={<Styles key='styles' />} />
                </Route>
              </Route>
            </Route>

            <Route path='/libraries/:libraryId/publishing' element={<Publishing key='publishing' />}>
              <Route path=':variant/:targetStatus' element={<Publishing key='publishing' />} />
            </Route>

            <Route path='/libraries/:libraryId/components/:componentId' element={<Editor />} />

            <Route path='/libraries/:libraryId/settings' element={<LibrarySettings />}>
              <Route path=':settingId' element={<LibrarySettings />} />
            </Route>

            <Route path='/libraries/:libraryId/plugins' element={<Plugins />}>
              <Route path=':componentId' element={<Plugins />} />
            </Route>
          </Routes>
        </BasicLayout>
      </AppBody>
    </BrowserRouter>
  )
}

function AppBody({ children }: { children: any }) {
  const originalNavigate = useNavigate()

  // custom navigate that allows changing url without notifying react router
  window.navigate = (to, options) => {
    if (typeof to == 'object') {
      to =
        (to.pathname || '') +
        (to.search ? (to.search.charAt(0) === '?' ? to.search : '?' + to.search) : '') +
        (to.hash ? (to.hash.charAt(0) === '#' ? to.hash : '#' + to.hash) : '')
    }
    if (options?.silent) {
      if (options.replace) {
        window.history.replaceState(null, '', to)
      } else {
        window.history.pushState(null, '', to)
      }
    } else {
      return originalNavigate(to, options)
    }
  }
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const link: HTMLAnchorElement = (e.target as HTMLElement).closest('a[href]')
      if (link && link.origin == location.origin && !link.closest('[contenteditable]')) {
        if (link.getAttribute('target') != '_blank') window.navigate(link)
        e.preventDefault()
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [])

  const getLibraryIdFromURL = (): string => {
    const match = location.pathname.match(/libraries\/([^\/?]+)/)
    if (match) return match[1]
    else return null
  }

  return (
    <>
      <AppContext libraryId={getLibraryIdFromURL()} navigate={window.navigate} handleErrors>
        {children}
      </AppContext>
    </>
  )
}

declare global {
  interface Window {
    navigate: CustomNavigateFunction
    aptrinsic: Appstrinsic
  }
}

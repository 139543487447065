import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { Style, isDeepEquals } from '@sitecore-feaas/sdk'
import { useRef, useState } from 'react'
import { Dot } from '../../Dot.js'
import Fieldsets, { StyleFormProps } from '../fieldsets/index.js'

const StyleForm = <T extends Style.Rule = Style.Rule>({
  mode,
  forms,
  rules,
  currentRule,
  onChange,
  rule,
  activeIds,
  errors,
  isNew,
  tabIndex,
  onTabChange
}: {
  mode: 'accordion' | 'tabs' | 'plain'
  forms?: Style.Form[]
  currentRule: T
  isNew: boolean
  onChange: (style: T) => void
  rule: T
  rules: Style.Rule[]
  errors: Style.RuleError<T>
  activeIds: string[]
  tabIndex?: number
  onTabChange?: (index: number) => void
}) => {
  const [forceShowErrors, setForceShowErrors] = useState(false)

  const onClick = (enabled: boolean) => {
    if (!enabled) {
      setForceShowErrors(true)
    }
  }

  const onFormChange = (value: T) => {
    setForceShowErrors(false)
    onChange(value)
  }

  const isFormChanged = (label: string, extractProperties: (props: unknown) => any) => {
    if (label === 'Details') {
      return !isDeepEquals({ ...currentRule.details, override: null }, { ...rule.details, override: null })
    }
    if (label === 'Custom CSS') {
      return !isDeepEquals(currentRule.details.override, rule.details.override)
    }
    return !isDeepEquals(extractProperties(rule.props), extractProperties(currentRule.props))
  }
  const isRuleChanged = !isDeepEquals(rule, currentRule)
  const ref = useRef(null)
  return (
    <Box as={mode === 'tabs' ? 'form' : 'div'} ref={ref}>
      {mode === 'tabs' && (
        <Tabs w='full' index={tabIndex} onChange={onTabChange} isManual isLazy={true}>
          <TabList>
            {forms.map(({ label, extractProperties }: Style.Form) => {
              const enabled = label === 'Details' || !!currentRule.details.title
              const changed = isFormChanged(label, extractProperties)
              const tooltip =
                label == 'Custom CSS' && currentRule.details.override
                  ? 'The style has CSS customizations'
                  : changed
                  ? 'Tab has unsaved changes'
                  : null
              return (
                <Tooltip label={tooltip} key={label} portalProps={{ containerRef: ref }}>
                  <Tab
                    fontWeight='semibold'
                    opacity={enabled ? 1 : 0.5}
                    cursor={enabled ? 'pointer' : 'not-allowed'}
                    onClick={() => onClick(enabled)}
                    aria-label={`Tab ${label}`}
                    pos='relative'
                    isDisabled={!!errors && isRuleChanged}
                    data-modified={changed}
                    whiteSpace='nowrap'
                  >
                    {label}
                    {changed ? <Dot pos='absolute' right='6px' /> : null}
                  </Tab>
                </Tooltip>
              )
            })}
          </TabList>

          <TabPanels>
            {forms.map(({ label, id, fieldset }: Style.Form, i) => {
              const Fieldset: React.FunctionComponent<StyleFormProps> = fieldset || Fieldsets[id]
              return (
                <TabPanel key={i} pt={6} pb={0} px={0} aria-label={`Tab ${i} Content`} position='relative'>
                  {typeof Fieldset === 'function' ? (
                    <Fieldset
                      currentRule={currentRule}
                      onChange={onFormChange}
                      rule={rule}
                      rules={rules}
                      errors={errors}
                      activeIds={activeIds}
                      forceShowErrors={forceShowErrors}
                      isActive={tabIndex ? i === tabIndex : true}
                    />
                  ) : (
                    Fieldset
                  )}
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
      )}
      {mode === 'accordion' && (
        <Accordion allowToggle>
          {forms.map(({ label, id, extractProperties }, index) => {
            const Fieldset: React.FunctionComponent<StyleFormProps> = Fieldsets[id]
            return (
              <AccordionItem key={index}>
                <AccordionButton p={4}>
                  <Text display='flex' alignItems='center' fontWeight='semibold' pos='relative' pr='4'>
                    {label} {isFormChanged(label, extractProperties) && <Dot />}
                  </Text>

                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel {...{ pt: 6, pb: 9 }}>
                  {typeof Fieldset === 'function' ? (
                    <Fieldset
                      currentRule={currentRule}
                      onChange={onChange}
                      rule={rule}
                      rules={rules}
                      errors={errors}
                      activeIds={activeIds}
                    />
                  ) : (
                    Fieldset
                  )}
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      )}
      {mode === 'plain' && (
        <Flex flexDirection='column'>
          {forms.map(({ label, id, extractProperties }, index) => {
            const Fieldset: React.FunctionComponent<StyleFormProps> = Fieldsets[id]
            return typeof Fieldset === 'function' ? (
              <Fieldset
                key={id}
                rules={rules}
                currentRule={currentRule}
                onChange={onChange}
                rule={rule}
                errors={errors}
                activeIds={activeIds}
              />
            ) : (
              Fieldset
            )
          })}
        </Flex>
      )}
    </Box>
  )
}

export default StyleForm

import { Badge, Box, Card, CardBody, Flex, HStack, Icon, Input, Spacer, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SettingsCard from './SettingsCard.js'
import { useLibrary } from '../../hooks/useData.js'
import { mdiCheckCircleOutline } from '@mdi/js'
import FieldsetField from '../FieldsetField.js'

const ContentHubOne = () => {
  const library = useLibrary()
  const contentHubOneDatasources = useLibrary('contentHubOneDatasources')
  const [contentHubOneDeliveryKey, setContentHubOneDeliveryKey] = useState<string>(
    library.settings.contentHubOneDeliveryKey
  )
  const [contentHubOnePreviewKey, setContentHubOnePreviewKey] = useState<string>(
    library.settings.contentHubOnePreviewKey
  )
  return (
    <SettingsCard
      name={'Content Hub ONE'}
      hasChanges={
        contentHubOneDeliveryKey !== library.settings.contentHubOneDeliveryKey ||
        contentHubOnePreviewKey !== library.settings.contentHubOnePreviewKey
      }
      onDiscard={() => {
        setContentHubOneDeliveryKey(library.settings.contentHubOneDeliveryKey)
        setContentHubOnePreviewKey(library.settings.contentHubOnePreviewKey)
      }}
      onSave={() => {
        library
          .save({ settings: { ...library.settings, contentHubOneDeliveryKey, contentHubOnePreviewKey } })
          .then(() => {
            library.contentHubOneDatasources.fetch()
          })
      }}
      top={
        <>
          {library.settings.contentHubOneDeliveryKey && !!contentHubOneDatasources.length && (
            <Badge colorScheme={'teal'} mr='2' px={2} fontSize={'md'}>
              <Flex alignItems={'center'}>
                <Icon mr={1}>
                  <path d={mdiCheckCircleOutline} />
                </Icon>{' '}
                Delivery API key
              </Flex>
            </Badge>
          )}
          {/*library.settings.contentHubOnePreviewKey && (
            <Badge colorScheme={'teal'} mr='2' px={2} fontSize={'md'}>
              <Icon>
                <path d={mdiCheckCircleOutline} />
              </Icon>{' '}
              Preview API key
            </Badge>
          )*/}
          {contentHubOneDatasources.length === 0 && (
            <Badge colorScheme={'blackAlpha'} mr='2' px={2} fontSize={'md'}>
              Not connected
            </Badge>
          )}
        </>
      }
    >
      <Flex justifyContent={'space-between'}>
        <Box>
          {/*
          <FieldsetField extraProps={{ width: '400px', marginTop: '10px', marginBottom: '10px' }} label='Preview API'>
            <HStack>
              <Input
                placeholder={'E.g. ckFFL2s4OEhDM2pRcW16YWVpUm5KWU5KRnFHZ0w1Wll'}
                value={contentHubOnePreviewKey}
                onChange={(e) => {
                  setContentHubOnePreviewKey(e.target.value)
                }}
              />
            </HStack>
          </FieldsetField>
*/}
          <FieldsetField extraProps={{ width: '400px', marginTop: '20px', marginBottom: '10px' }} label='Delivery API'>
            <HStack>
              <Input
                placeholder={'E.g. ckFFL2s4OEhDM2pRcW16YWVpUm5KWU5KRnFHZ0w1Wll'}
                value={contentHubOneDeliveryKey}
                onChange={(e) => {
                  setContentHubOneDeliveryKey(e.target.value)
                }}
              />
            </HStack>
          </FieldsetField>
        </Box>
        <Box maxWidth={'300px'}>
          <Card variant={'filled'} bgColor={'blackAlpha.50'}>
            <CardBody>
              <VStack align={'left'} spacing={2}>
                <Text fontWeight={600}>Delivery API IDE</Text>
                <Text fontSize={'12px'}>The delivery API allows access to approved and published content</Text>
                {/*<Spacer />
                <Text fontWeight={600}>Preview API IDE</Text>
                <Text fontSize={'12px'}>
                  Preview API allows access to all your content, including drafts and other content that is not yet
                  approved
                </Text>*/}
              </VStack>
            </CardBody>
          </Card>
        </Box>
      </Flex>
    </SettingsCard>
  )
}

export default ContentHubOne

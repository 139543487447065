import { HStack, Icon, IconButton, Input, Text } from '@chakra-ui/react'
import { mdiContentCopy } from '@mdi/js'
import copy from 'copy-to-clipboard'
import React, { useEffect, useState } from 'react'
import { useLibrary } from '../../hooks/useData.js'
import FieldsetField from '../FieldsetField.js'
import SettingsCard from './SettingsCard.js'

const MainSetting = () => {
  const library = useLibrary()
  const [name, setName] = useState<string>(library.name)
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (!name.length) setError('Please fill library name')
    else setError(null)
  }, [name])

  return (
    <SettingsCard
      name={library.name}
      description={'Unique name of your Component library'}
      hasChanges={library.name !== name}
      onSave={async () => {
        library.save({ name })
      }}
      onDiscard={() => {
        setName(library.name)
      }}
      hasErrors={!!error}
    >
      <FieldsetField extraProps={{ maxW: '80' }} label='Name' error={error} required={true}>
        <Input
          isInvalid={!name.length}
          placeholder='Enter name'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </FieldsetField>
    </SettingsCard>
  )
}

export default MainSetting

import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Spacer,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { Filter, PageContainer } from '../styled.js'
import Search from '../Search.js'
import { css } from '@emotion/react'
import PublisherComponentsCollection from './PublisherComponentsCollection.js'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { QueryStringContext } from '../providers/QueryStringProvider.js'
import { CollectionModel, ComponentModel, VersionModel } from '@sitecore-feaas/sdk'
import PublisherComponentsFooter from './PublisherComponentsFooter.js'
import { useLibrary } from '../../hooks/useData.js'
import useDocumentTitle from '../../hooks/useDocumentTitle.js'
import { mdiCheckCircle } from '@mdi/js'
import useFilter from '../../hooks/useFilter.js'

export type ComponentsTargetStatus = 'staged' | 'published'
export type FetchStatus = 'initial' | 'fetching' | 'done'

export const filterSortComponents = (components: ComponentModel[], versions: VersionModel[]) => {
  const versionIds: VersionModel['id'][] = versions.map((v) => v.getId())
  return components
    .filter((comp) => comp.versions.some((v) => versionIds.includes(v.getId())))
    .sort((a: ComponentModel, b: ComponentModel) => a.name.localeCompare(b.name))
}
export const filterCollections = (collections: CollectionModel[], versions: VersionModel[]) =>
  collections.filter((col) => filterSortComponents(col.components, versions).length)

const PublisherComponents = ({ targetStatus }: { targetStatus: ComponentsTargetStatus }) => {
  const toast = useToast()
  const [query, setQuery] = useContext(QueryStringContext)
  const collections = useLibrary('collections')
  const components = useLibrary('components')

  const [hideNeverStaged, setHideNeverStaged] = useState<boolean>(false)
  const [versionsFetchStatus, setVersionsFetchStatus] = useState<FetchStatus>()
  const [selectedVersionIds, setSelectedVersionIds] = useState<VersionModel['id'][]>([])

  const versionsToBe = useMemo<{
    staged: VersionModel[]
    published: VersionModel[]
  }>(() => {
    if (versionsFetchStatus !== 'done') return { staged: [], published: [] }
    const toBeStagedVersions: VersionModel[] = []
    const toBePublishedVersions: VersionModel[] = []
    collections.forEach((collection) => {
      collection.components.forEach((component) => {
        toBeStagedVersions.push(...component.getNotDeletedVersions().filter((version) => version.status === 'saved'))
        toBePublishedVersions.push(
          ...component.getNotDeletedVersions().filter((version) => version.status === 'staged')
        )
      })
    })
    return { staged: toBeStagedVersions, published: toBePublishedVersions }
  }, [versionsFetchStatus, components])

  const [collectionOptions, collectionSelectedOptions, onCollectionOptionsChange, filteredCollectionIds] =
    useFilter<CollectionModel>(filterCollections(collections, versionsToBe[targetStatus]), 'collectionId')

  const matchVersion = (version: VersionModel) =>
    // collection dropdown
    filteredCollectionIds.includes(version.component.collectionId) &&
    // search
    (query.search
      ? version.matchesSearch(query.search) ||
        version.component.matchesSearch(query.search) ||
        version.component.collection.matchesSearch(query.search)
      : true) &&
    // never published toggle
    (hideNeverStaged ? !!version.getCurrent(targetStatus) : true)

  const filteredVersionsToBe = useMemo<{
    staged: VersionModel[]
    published: VersionModel[]
  }>(
    () => ({
      staged: versionsToBe.staged.filter((version) => matchVersion(version)),
      published: versionsToBe.published.filter((version) => matchVersion(version))
    }),
    [versionsToBe, hideNeverStaged, query.search, filteredCollectionIds]
  )

  const currentCollections = filterCollections(
    filteredCollectionIds.map((id) => collections.find((c) => c.id === id)),
    filteredVersionsToBe[targetStatus]
  )

  useEffect(() => {
    setSelectedVersionIds([])
  }, [targetStatus])

  useEffect(() => {
    setVersionsFetchStatus('fetching')
    let promises: Promise<VersionModel[]>[] = []
    collections.forEach((collection) => {
      collection.components.forEach((component) => {
        promises.push(component.versions.fetch())
      })
    })
    Promise.all(promises)
      .then(() => {
        setVersionsFetchStatus('done')
      })
      .catch((err) => {
        setVersionsFetchStatus('done')
        requestAnimationFrame(() => {
          toast({
            duration: 4000,
            status: 'error',
            title: 'There was an error trying to load versions',
            description: err.message
          })
        })
      })
  }, [])

  useDocumentTitle('Component publishing')

  return (
    <>
      <Box height={'full'} flex={1}>
        <Container {...PageContainer}>
          <Flex align='center' wrap='wrap'>
            <Text as='h2' fontSize='4xl' fontWeight='semibold'>
              Components
            </Text>
          </Flex>

          <Spacer />

          <Flex align='center' wrap='wrap' mt={6}>
            <Box w='xs' mr='4'>
              <Search />
            </Box>

            <Box mr='4'>
              <Filter
                placeholder='Collections'
                options={collectionOptions}
                onChange={onCollectionOptionsChange}
                value={collectionSelectedOptions}
              />
            </Box>

            <Box>
              <Button
                color={'primary.600'}
                colorScheme={'primary'}
                fontSize={'12px'}
                onClick={() => {
                  onCollectionOptionsChange([])
                  setQuery({ search: null })
                }}
              >
                Clear all
              </Button>
            </Box>
          </Flex>

          <Flex>
            <FormControl display='flex' alignItems='center' width='auto' mt={2}>
              <Switch
                id={'hide-not-published-toggle'}
                isChecked={hideNeverStaged}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setHideNeverStaged(e.target.checked)
                }}
                size='md'
              />
              <FormLabel htmlFor='hide-not-published-toggle' mb='0' ml={2}>
                <Text>Hide components that have not been {targetStatus} yet</Text>
              </FormLabel>
            </FormControl>
          </Flex>

          <Box mt='10' height={'100%'}>
            {versionsFetchStatus !== 'done' ? (
              <Flex height={'100%'} justifyContent={'center'} alignItems={'center'}>
                <Spinner size='xl' />
              </Flex>
            ) : currentCollections.length ? (
              <Accordion reduceMotion allowMultiple defaultIndex={[...Array(collections.length).keys()]}>
                {currentCollections?.map((col) => (
                  <AccordionItem
                    key={col.id}
                    css={css`
                      border: 0;
                      outline: none !important;
                    `}
                    marginTop={6}
                    _hover={{ backgroundColor: 'white' }}
                  >
                    {({ isExpanded }: { isExpanded: boolean }) => (
                      <PublisherComponentsCollection
                        versions={filteredVersionsToBe[targetStatus]}
                        collection={col}
                        targetStatus={targetStatus}
                        selectedVersionIds={selectedVersionIds}
                        onSelect={setSelectedVersionIds}
                        isExpanded={isExpanded}
                      />
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            ) : (
              <Flex alignSelf={'center'} justifyContent={'center'}>
                <VStack>
                  <Icon boxSize={'50px'}>
                    <path color={'#00a182'} d={mdiCheckCircle} />
                  </Icon>
                  <Text maxW={270} textAlign={'center'} fontWeight={600}>
                    {versionsToBe[targetStatus].length === 0
                      ? `All components are ${targetStatus}`
                      : `No components matching your criteria to be ${targetStatus}`}
                  </Text>
                </VStack>
              </Flex>
            )}
          </Box>
        </Container>
      </Box>
      {selectedVersionIds.length > 0 && (
        <PublisherComponentsFooter
          targetStatus={targetStatus}
          versions={filteredVersionsToBe[targetStatus]}
          selectedVersionIds={selectedVersionIds}
          onPublish={() => {
            setSelectedVersionIds([])
          }}
        />
      )}
    </>
  )
}

export default PublisherComponents

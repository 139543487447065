import { Box, HStack, Icon, IconButton, Text, Tooltip, useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import { mdiContentCopy } from '@mdi/js'
import React from 'react'
import { useLibrary } from '../../exports.js'

const ApiKey = () => {
  const library = useLibrary()
  const toast = useToast()
  return (
    <Box
      position='relative'
      borderRadius='base'
      marginTop='5'
      layerStyle={'outline'}
      className={'entity-wrapper'}
      padding={5}
    >
      <Box slot='details' maxH='200px'>
        <Text fontSize={'x-large'} fontWeight={'600'} mr={3} mb={2} noOfLines={1}>
          Your component library API key
        </Text>
      </Box>
      <Box>
        <HStack>
          <Text fontSize='md' mr={2} color={'blackAlpha.500'} fontWeight={600}>
            API key
          </Text>
          <Text fontSize='md'>{library.apiKey}</Text>
          <Tooltip label={'Copy'}>
            <IconButton
              onClick={() => {
                copy(library.apiKey)
                toast({
                  duration: 4000,
                  status: 'success',
                  title: 'Your component library API key is copied'
                })
              }}
              aria-label={'copy'}
              icon={
                <Icon>
                  <path d={mdiContentCopy} />
                </Icon>
              }
            />
          </Tooltip>
        </HStack>
      </Box>
    </Box>
  )
}

export default ApiKey

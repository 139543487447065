import React, { useMemo } from 'react'
import { mdiMagnify } from '@mdi/js'
import { Select } from './Select.js'
import { components, PlaceholderProps, Props } from 'chakra-react-select'
import { Option, OptionGroup } from '../types/index.js'
import {
  Button,
  Flex,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  InputRightElement,
  Tooltip,
  CloseButton,
  Tag,
  ListItem,
  List,
  Checkbox,
  Divider,
  Box,
  ContainerProps,
  FlexProps,
  TextProps,
  BoxProps
} from '@chakra-ui/react'
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components/index.js'
import { GroupBase } from 'react-select/dist/declarations/src/types.js'

export const removeProps = {
  shouldForwardProp: (prop: string) =>
    ![
      'isActive',
      'silenced',
      'highlightOnHover',
      'inGroup',
      'documentHeight',
      'clearValue',
      'isFocused',
      'hasActive',
      // 'getStyles',
      'getValue',
      'isMulti',
      'isRtl',
      'selectOption',
      'selectProps',
      'setValue',
      'isDisabled',
      // 'innerProps',
      // 'cx',
      'isActive',
      'hasValue'
    ].includes(prop)
}

export const PageContainer: ContainerProps = {
  maxWidth: 'container.xxl',
  padding: '10',
  paddingBottom: '10rem'
}

export const PageDescription: FlexProps = {
  alignItems: 'center',
  wrap: 'wrap',
  justifyContent: 'space-between'
}

export const PageTitle: TextProps = {
  fontSize: '4xl',
  fontWeight: 'semibold'
}

export const PageAction = {
  alignItems: 'center',
  wrap: 'wrap',
  justifyContent: 'space-between'
}

export const TruncatedLabel: TextProps = {
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'primary.400'
}

export const Plus = {
  marginLeft: '2'
}

export const Placeholder = {
  fontWeight: 'semibold'
}

export const Label = {
  fontWeight: 600
}

export const FilterWrapper: BoxProps = {
  backgroundColor: 'white',
  padding: '3',
  minWidth: '250px',
  maxHeight: '350px',
  overflowY: 'auto'
}

const { MultiValue: MultiValueComponent, MultiValueRemove: MultiValueRemoveComponent } = components

const Mvc = {
  marginLeft: '2',
  display: 'flex'
}

const MultiValue = ({ index, getValue, placeholder, ...props }: any) => {
  const maxToShow = 1
  const overflow = getValue()
    .slice(maxToShow)
    .map(({ label }: Option) => label)
  const reachedLimit = index === maxToShow

  if (index < maxToShow)
    return (
      <Flex>
        <Text {...Placeholder}>{placeholder}:</Text>
        <MultiValueComponent {...props}>{props.children}</MultiValueComponent>
      </Flex>
    )

  if (reachedLimit)
    return <Tag {...Plus} colorScheme='primary' variant='subtle' size='sm'>{`+ ${overflow.length}`}</Tag>

  return null
}

export const Filter = ({ className, options, onChange, placeholder, value }: Props) => {
  const components = useMemo<SelectComponentsConfig<Option, boolean, GroupBase<Option>>>(
    () => ({
      MultiValue: (props) => <MultiValue {...props} placeholder={placeholder} />,
      MultiValueContainer: ({ children }) => (
        <Tag {...Mvc} colorScheme='primary' variant='subtle' size='sm' {...{ className, children }} />
      ),
      MultiValueRemove: (props) => <MultiValueRemoveComponent {...props} />,
      MultiValueLabel: ({ children }) => <Text {...TruncatedLabel} {...{ className, children }} />,
      Placeholder: ({ className, children, hasValue }: PlaceholderProps) => (
        <>
          <Text {...Label} {...{ className, children }} /> {!hasValue && ': All'}
        </>
      ),
      MenuList: (props) => {
        const optionsSelected = props.getValue()
        return (
          <Box {...FilterWrapper}>
            <List>
              {props.options.map((option: Option | OptionGroup) => (
                <ListItem
                  p={2}
                  key={option.label}
                  onClick={(e) => {
                    if ('options' in option) return
                    props.selectOption(option)
                  }}
                >
                  {!('options' in option) && (
                    <Checkbox isChecked={!!optionsSelected.find((os: Option) => os.value === option.value)} size='lg'>
                      {option.label}
                    </Checkbox>
                  )}

                  {'options' in option && (
                    <Box>
                      <Box
                        onClick={(e) => {
                          props.setValue(
                            !optionsSelected.find((os: Option) => os.value === option.options[0].value)
                              ? [...optionsSelected, ...option.options]
                              : optionsSelected.filter(
                                  (os: Option) => !option.options.find((oo: Option) => oo.value === os.value)
                                ),
                            'select-option'
                          )
                        }}
                      >
                        <Checkbox
                          fontWeight='bold'
                          isChecked={
                            !!option.options.every((os: Option) =>
                              optionsSelected.find((o: Option) => o.value === os.value)
                            )
                          }
                          size='lg'
                        >
                          {option.label}
                        </Checkbox>
                      </Box>

                      <List pl='4' mt='2'>
                        {option.options.map((o: Option) => (
                          <ListItem p={2} key={o.label} onClick={(e) => props.selectOption(o)}>
                            <Checkbox
                              isChecked={!!optionsSelected.find((os: Option) => os.value === o.value)}
                              size='lg'
                            >
                              {o.label}
                            </Checkbox>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </ListItem>
              ))}
            </List>

            <Divider my='3' />

            <Flex>
              <Button onClick={() => props.clearValue()}>Clear all</Button>
            </Flex>
          </Box>
        )
      }
    }),
    [placeholder, className]
  )

  return (
    <Select
      className={className}
      isMulti={true}
      value={value}
      placeholder={placeholder}
      options={options}
      closeMenuOnSelect={false}
      onChange={onChange}
      isSearchable={false}
      components={components}
    />
  )
}

export const Search = ({
  className,
  value,
  onChange,
  onClear,
  placeholder
}: {
  className?: string
  value: string
  onChange: (value: string) => void
  onClear: () => void
  placeholder?: string
}) => (
  <InputGroup maxW='2xs' className={className}>
    <InputLeftElement
      pointerEvents='none'
      children={
        <Icon boxSize='icon-lg' color='blackAlpha.500'>
          <path d={mdiMagnify} />
        </Icon>
      }
    />

    <Input onChange={(e) => onChange(e.target.value)} placeholder={placeholder || 'Search'} value={value} />

    {value && (
      <InputRightElement onClick={onClear}>
        <Tooltip label='Clear'>
          <CloseButton />
        </Tooltip>
      </InputRightElement>
    )}
  </InputGroup>
)

export const Title: TextProps = {
  maxWidth: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'blackAlpha.800'
}

export const LargeTitle: TextProps = {
  color: 'blackAlpha.800',
  fontWeight: 'semibold',
  fontSize: '2xl'
}

export const Description: TextProps = {
  color: 'blackAlpha.500',
  fontSize: 'lg'
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  VStack,
  Box,
  ModalFooter,
  Link
} from '@chakra-ui/react'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'
import React from 'react'
import { useSDK } from '../../hooks/useData.js'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
const InstallDialog = ({ component }: { component: ExternalComponentModel }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const renderingHost = useSDK('renderingHost')
  const importStatement = component.getImportStatement()
  return (
    <>
      <Button variant={'primary'} onClick={onOpen} mb={6}>
        Install
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Installation instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Box fontSize={14} fontWeight={400}>
                <Text mb={6}>
                  In order to install {component.title} for host <b>{renderingHost.url}</b> the following steps need to
                  take place:
                </Text>
                <UnorderedList ml={6} fontWeight={600} mb={6}>
                  <ListItem>
                    {' '}
                    Use the latest version of{' '}
                    <Link href={'https://www.npmjs.com/package/@sitecore-jss/sitecore-jss-nextjs'}>
                      @sitecore-jss/sitecore-jss-nextjs
                    </Link>
                  </ListItem>
                  <ListItem>
                    {' '}
                    Use the latest version of{' '}
                    <Link href={'https://www.npmjs.com/package/@sitecore/components'}>@sitecore/components</Link>
                  </ListItem>{' '}
                  <ListItem> Import the {component.title} package us instructed below:</ListItem>{' '}
                </UnorderedList>
                <Text mb={3}>
                  In <b>byoc/index.hybrid.tsx</b> file, ensure you have{' '}
                  {importStatement ? 'the following ' : 'the corresponding '} import statement
                </Text>

                <SyntaxHighlighter
                  id='plugin-install-code'
                  language={'jsx'}
                  style={dark}
                  customStyle={{
                    borderRadius: '4px',
                    fontSize: '14px',
                    flexShrink: '1',
                    overflow: 'auto',
                    margin: '0'
                  }}
                >
                  {importStatement
                    ? component.getImportStatement()
                    : ' // This is an example. Please use your component name \n import ./MyByocComponent'}
                </SyntaxHighlighter>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={'primary'} onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InstallDialog

import { Box, BoxProps, Button, Divider, Flex, HStack, Icon, Skeleton, useToast } from '@chakra-ui/react'
import { mdiOpenInNew, mdiPlus } from '@mdi/js'
import { VersionModel } from '@sitecore-feaas/sdk'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Editor from '../components/editor/Editor.js'
import { EditorPicker } from '../components/editor/EditorPicker.js'
import Chrome from '../components/editor/chrome/Chrome.js'
import { EditorUIBreadcrumbs } from '../components/editor/ui/UIBreadcrumbs.js'
import { EditorUIMode } from '../components/editor/ui/UIMode.js'
import { EditorUISave } from '../components/editor/ui/UISave.js'
import { EditorUIUndo } from '../components/editor/ui/UIUndo.js'
import Version from '../components/editor/version/Version.js'
import { EnvironmentContext } from '../components/providers/EnvironmentProvider.js'
import { LibraryContext } from '../components/providers/LibraryProvider.js'
import { useLibrary, useModelObserver, useSDK } from '../hooks/useData.js'
import useDocumentTitle from '../hooks/useDocumentTitle.js'
import { SDKContext } from '../components/providers/SDKProvider.js'

const Border = (position: any): BoxProps => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  pointerEvents: 'none',
  backgroundPosition: position
})

const Vertical = (position: any): BoxProps => ({
  ...Border(position),
  backgroundImage: 'linear-gradient(#4a37d5 33%, rgba(255, 255, 255, 0) 0%)',
  backgroundSize: '1px 8px',
  backgroundRepeat: 'repeat-y'
})

const Horizontal = (position: any): BoxProps => ({
  ...Border(position),
  backgroundImage: 'linear-gradient(to right, #4a37d5 33%, rgba(255, 255, 255, 0) 0%)',
  backgroundSize: '8px 1px',
  backgroundRepeat: 'repeat-x'
})

export default function EditorRoute() {
  const navigate = useNavigate()
  const env = useContext(EnvironmentContext)
  const [library] = useContext(LibraryContext)
  const { componentId } = useParams()
  const [{ sdk, status: appStatus }] = useContext(SDKContext)
  const toast = useToast()
  const collections = useLibrary('collections')
  const datasources = useSDK('datasources')
  const component = useLibrary('components').find((c) => c.id == componentId)
  const [editedComponent, setEditedComponent] = useState(component)
  const versions = useModelObserver(editedComponent?.versions) || ([] as VersionModel[])
  const expandedComponents = useSDK('renderingHost.expandedComponents')
  useDocumentTitle('Builder')

  const [readyState, setReadyState] = useState(false)
  useEffect(() => {
    editedComponent?.versions.fetch().then((versions) => {
      if (!versions.length) {
        editedComponent.addVersion()
        setReadyState(true)
      }
    })
    editedComponent?.getBundles().forEach((bundle) => {
      if (bundle.isNew) bundle.save()
    })
  }, [editedComponent])

  useEffect(() => {
    if (appStatus == 'ready' && !component) {
      toast({
        isClosable: true,
        duration: 4000,
        status: 'warning',
        title: `Component was not found`,
        description: `Returning you back to the library`
      })
      navigate(`${library.getPath()}/collections`)
    } else if (appStatus === 'ready' && !editedComponent) {
      setEditedComponent(component)
    }
  }, [appStatus, component])

  if (!editedComponent) return
  const snapshots = editedComponent.reduceVersionRevisions(versions)
  const deletedSnapshots = snapshots.filter((snapshot) => {
    return snapshot.saved?.deletedAt
  })

  useEffect(() => {
    const layout = document.querySelector('#layout') as HTMLElement
    layout.classList.add('fixed-height-layout')
    return () => {
      layout.classList.remove('fixed-height-layout')
    }
  }, [])

  if (!editedComponent) return
  return (
    <>
      <Flex
        direction='row'
        position='relative'
        maxHeight='100%'
        className='editor'
        background='gray.50'
        alignItems='stretch'
        flexGrow={1}
        width='100%'
      >
        <Editor sdk={sdk} library={library} datasources={datasources} component={editedComponent} versions={versions}>
          {{
            chrome: ({ editor }) => editor && <Chrome />,
            picker: ({ editor }) => (
              <EditorPicker component={editedComponent} stylesheet={library.stylesheet} collections={collections} />
            ),
            before: ({ editor, focus, status }) => (
              <>
                <HStack background='gray.50' pb={10} justifyContent='space-between' width='100%' mx='auto'>
                  <EditorUIBreadcrumbs component={editedComponent} library={library} />
                  <HStack
                    className='ui'
                    whiteSpace={'nowrap'}
                    spacing='2'
                    divider={<Divider orientation='vertical' colorScheme='red' height='8' />}
                  >
                    {' '}
                    {expandedComponents.length > 0 && (
                      <HStack justifyContent='center' spacing='8' py='8'>
                        <Button
                          variant='secondary'
                          justifyContent={'center'}
                          onClick={() => {
                            window.open(
                              `${library.getPreviewURL()}?feaasSrc=${env.cdn}/components/${library.id}/${
                                editedComponent.id
                              }/responsive/staged`
                            )
                          }}
                        >
                          Preview external components{' '}
                          <Icon ml={2}>
                            <path d={mdiOpenInNew} />
                          </Icon>
                        </Button>
                      </HStack>
                    )}
                    <HStack justifyContent='center' spacing='8' py='8'>
                      <EditorUIMode />
                    </HStack>
                    <HStack spacing={2}>
                      <Button
                        variant='secondary'
                        leftIcon={
                          <Icon fontSize={'icon-lg'}>
                            <path d={mdiPlus} />
                          </Icon>
                        }
                        onClick={() => {
                          focus(editedComponent.addVersion().id)
                        }}
                        isDisabled={!editor}
                      >
                        Add version
                      </Button>
                      <EditorUISave />
                    </HStack>
                  </HStack>
                </HStack>
                <HStack position='fixed' bottom={4} mr={0} justifyContent='flex-end' zIndex={20} alignSelf='flex-end'>
                  <EditorUIUndo editor={status == 'ready' ? editor : null} />
                </HStack>
                <Box
                  position={'absolute'}
                  top={'145px'}
                  zIndex={100}
                  transition='opacity 0.3s'
                  opacity={status == 'ready' ? 0 : 1}
                  mx={'auto'}
                  width={'1400px'}
                  px={0}
                  left={0}
                  right={0}
                  pointerEvents='none'
                  maxWidth={'calc(100% - 48px)'}
                >
                  {'Cypress' in window && (
                    <Box>
                      {versions.length}/{status}
                    </Box>
                  )}
                  <Skeleton
                    position={'absolute'}
                    left={0}
                    top={0}
                    height='32px'
                    width={'100px'}
                    maxWidth={'100%'}
                    isLoaded={false}
                  ></Skeleton>
                  <Skeleton
                    position={'absolute'}
                    top={0}
                    right={0}
                    height='32px'
                    width={'545px'}
                    isLoaded={false}
                  ></Skeleton>
                  <Skeleton
                    position='absolute'
                    top='42px'
                    height='100px'
                    width={'1400px'}
                    maxWidth='100%'
                    isLoaded={false}
                  ></Skeleton>
                </Box>
              </>
            ),
            children: ({}) =>
              snapshots
                .filter((snapshot) => {
                  return !snapshot.saved?.deletedAt
                })
                .map((snapshot) => <Version key={snapshot.head.id} version={snapshot.head} />),
            extras: ({ editor, status, focus, isArchivedDisplayed }, setEditorContext) =>
              [
                <Flex
                  pos='relative'
                  h='140px'
                  cursor='pointer'
                  align='center'
                  justifyContent='center'
                  p='4'
                  onClick={() => focus(editedComponent.addVersion().id)}
                  w='full'
                  _hover={{ bg: 'primary.50' }}
                  maxWidth='1400px'
                  mt={status != 'ready' ? '272px !important' : '128px !important'}
                  mx='24px'
                  mb='200px'
                >
                  <Box {...Vertical('left')} />
                  <Box {...Vertical('right')} />
                  <Box {...Horizontal('bottom')} />
                  <Box {...Horizontal('top')} />

                  <Flex align='center' justifyContent='center' w='full' h='full' borderRadius='base'>
                    <Button
                      fontWeight='semibold'
                      variant='subtle'
                      _hover={{ bg: 'transparent' }}
                      _active={{ bg: 'transparent' }}
                    >
                      <Icon mr='2'>
                        <path d={mdiPlus} />
                      </Icon>
                      Add version
                    </Button>
                  </Flex>
                </Flex>,

                deletedSnapshots.length > 0 && (
                  <Button
                    variant='secondary'
                    colorScheme='gray'
                    onClick={() => {
                      setEditorContext({ isArchivedDisplayed: !isArchivedDisplayed })
                    }}
                  >
                    {isArchivedDisplayed ? 'Hide' : 'Show'} {deletedSnapshots.length} archived version
                    {deletedSnapshots.length > 1 ? 's' : ''}
                  </Button>
                )
              ].concat(
                deletedSnapshots.map((snapshot) => (
                  <Version key={snapshot.head.id} version={snapshot.head} isHidden={!isArchivedDisplayed} />
                ))
              ),
            after: (
              <>
                <Box height='200px' flexShrink={0}></Box>
              </>
            )
          }}
        </Editor>
      </Flex>
    </>
  )
}

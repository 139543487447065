import { useNavigate, useSearchParams } from 'react-router-dom'

const useNavigateWithQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  let queryParams = '?'

  let hasParam = false
  for (const key of searchParams.entries()) {
    hasParam = true
    queryParams = queryParams + key[0] + '=' + key[1] + '&'
  }
  if (hasParam) queryParams = queryParams.slice(0, queryParams.length - 1)

  return (url: string, options?: object) => navigate(`${url}${queryParams}`, options)
}

export default useNavigateWithQueryParams

import { Flex } from '@chakra-ui/react'
import React from 'react'
import PublisherSidebar from '../components/publisher/PublisherSidebar.js'
import PublisherComponents, { ComponentsTargetStatus } from '../components/publisher/PublisherComponents.js'
import PublisherStyles from '../components/publisher/PublisherStyles.js'
import { useParams } from 'react-router'
import type { StylesheetModel } from '@sitecore-feaas/sdk'

export type PublisherVariant =
  | 'components'
  | 'styles'

const Publishing = () => {
  const { targetStatus, variant } = useParams() as { targetStatus: StylesheetModel['status'], variant: PublisherVariant }

  return (
    <Flex w='full' h='full'>
      <PublisherSidebar variant={variant} targetStatus={targetStatus} />
      {(variant === 'components') && (
        <PublisherComponents targetStatus={(targetStatus || 'staged') as ComponentsTargetStatus} />
      )}
      {(variant === 'styles') && (
        <PublisherStyles key={variant + targetStatus} targetStatus={targetStatus} />
      )}
    </Flex>
  )
}

export default Publishing

import { Box, BoxProps, Button, Flex, Text, VStack } from '@chakra-ui/react'
import { Style } from '@sitecore-feaas/sdk'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useLibrary } from '../../hooks/useData.js'
import EntitiesProvider from '../entities/EntitiesProvider.js'
import { PreviewFontsStylesheet } from './previews/index.js'
import { StylePage } from './sidebar.js'
import StyleComponent from './style/Style.js'
import EmptyContent from '../EmptyContent.js'

const StylesListWrapper: BoxProps = {
  height: 'full',
  padding: '8',
  flex: 1,
  paddingBottom: '100px',
  minWidth: '0px'
}

const List: BoxProps = {
  marginTop: '8'
}

const StyleList = ({ activeStylePage }: { activeStylePage: StylePage }) => {
  const stylesheet = useLibrary('stylesheets.first')
  const themeCollection = {
    details: { id: 'theme', title: 'Theme', isInternal: true },
    props: { type: 'theme' },
    type: 'collection'
  } as Style.Rule<'collection'>
  /** Insert a theme collection rule, this acts as a wrapper to display themes in a grid */
  const rules = [...useLibrary('stylesheets.first.rules'), themeCollection]
  const library = useLibrary()
  const { collection: activeCollection = 'basics', type: activeStyleType = 'font', styleId } = useParams()
  const style = rules.find((s) => s.details.id === styleId)
  const [active, setActive] = useState([style].filter(Boolean))
  const navigate = useNavigate()

  useEffect(() => {
    setActive([style].filter(Boolean))
  }, [styleId])

  const onCreate = () => {
    const specialTypes = ['theme', 'font']
    const specialTypeIndex = specialTypes.indexOf(activeStyleType)
    const type = (specialTypeIndex !== -1 ? specialTypes[specialTypeIndex] : 'collection') as Style.Type
    let props
    if (type === 'collection') {
      props = { type: activeStyleType }
    } else if (type === 'theme') {
      props = { blocks: [], texts: [], inlines: [] }
    }

    const newStyle = stylesheet.upsertRule(Style.Rule({ type, props }))
    navigate(`${library.getPath()}/styles/${activeCollection}/${activeStyleType}/${newStyle.details.id}`)
  }
  const collections =
    activeStyleType === 'font'
      ? rules.filter((s) => s.type === 'font')
      : rules
          .filter((s) => s.type === 'collection' && s.props.type === activeStyleType)
          .sort((a, b) => (a.details.isInternal ? 1 : b.details.isInternal ? -1 : 0))

  const getAddEntity = () => {
    switch (activeStyleType) {
      case 'font':
        return 'font'
      case 'block':
        return 'custom element'
      case 'inline':
        return 'custom element'
      default:
        return 'collection'
    }
  }

  return (
    <>
      <Box {...StylesListWrapper}>
        <Flex alignItems='center'>
          <Text as='h2' fontWeight='semibold' fontSize='4xl'>
            {activeStylePage.title}
          </Text>

          {activeStyleType !== 'text' && activeStyleType !== 'theme' && (
            <Box ml='auto'>
              <Button variant='primary' colorScheme='primary' size='md' onClick={onCreate}>
                Add {getAddEntity()}
              </Button>
            </Box>
          )}
        </Flex>

        {activeStyleType === 'breakpoint' && (
          <Text>
            Breakpoint definitions are organized from largest to smallest breakpoint within each specific collection
          </Text>
        )}

        <PreviewFontsStylesheet styles={rules} />

        {collections.length > 0 ? (
          <EntitiesProvider
            url={`${library.getPath()}/styles/${activeCollection}/${activeStyleType}`}
            activeEntityId={styleId}
            ids={rules.map(({ details }) => details.id)}
          >
            <Box {...List} className='styles-list' id='overflow-wrapper'>
              {collections.map((collection, index) => {
                const children =
                  activeStyleType === 'theme'
                    ? rules.filter((s) => s.type === 'theme')
                    : stylesheet.getCollectionItems(collection)
                const showChildren = children.length > 0 && activeStyleType !== 'font'
                return (
                  <StyleComponent
                    rules={rules}
                    stylesheet={stylesheet}
                    key={collection.details.id}
                    rule={collection}
                    index={index}
                    childrenRules={
                      showChildren &&
                      children.map((style, i) => (
                        <StyleComponent
                          rules={rules}
                          stylesheet={stylesheet}
                          key={style.details.id}
                          rule={style}
                          index={i}
                        />
                      ))
                    }
                  />
                )
              })}
            </Box>
          </EntitiesProvider>
        ) : (
          <EmptyContent
            title={activeStyleType !== 'font' ? 'Nothing created yet' : 'Nothing added yet'}
            subtitle={
              <VStack>
                <Box mb={2}>
                  {activeStyleType !== 'font' ? 'Start by creating collection' : 'Add first font library'}
                </Box>
                <Button variant={'primary'} onClick={onCreate}>
                  {activeStyleType !== 'font' ? 'Create' : 'Add font'}
                </Button>
              </VStack>
            }
          />
        )}
      </Box>
    </>
  )
}

export default StyleList

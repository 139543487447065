import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Text
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { CollectionModel, ComponentModel, VersionModel } from '@sitecore-feaas/sdk'
import PublisherComponentsComponent from './PublisherComponentsComponent.js'
import { useMemo } from 'react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { filterSortComponents, ComponentsTargetStatus } from './PublisherComponents.js'

const PublisherComponentsCollection = ({
  versions,
  collection,
  targetStatus,
  selectedVersionIds,
  onSelect,
  isExpanded
}: {
  versions: VersionModel[]
  collection: CollectionModel
  targetStatus: ComponentsTargetStatus
  selectedVersionIds: string[]
  onSelect: (id: string[]) => void
  isExpanded: boolean
}) => {
  const components = useMemo<ComponentModel[]>(
    () => filterSortComponents(collection.components, versions),
    [collection.components, versions]
  )

  const collectionVersions = useMemo<VersionModel[]>(() => {
    const versionIds = versions.map((v) => v.getId())
    return components.flatMap((comp) => comp.versions).filter((v) => versionIds.includes(v.getId()))
  }, [components, versions])

  const selectedAll = collectionVersions.every((ver) => selectedVersionIds.includes(ver?.getId()))
  const selectedSome = !selectedAll && collectionVersions.some((ver) => selectedVersionIds.includes(ver?.getId()))
  const selectedNumber = collectionVersions.filter((ver) => selectedVersionIds.includes(ver?.getId())).length

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const toBeAdded: string[] = []
      collectionVersions.forEach((ver) => {
        if (!selectedVersionIds.includes(ver.getId())) toBeAdded.push(ver.getId())
      })
      onSelect([...selectedVersionIds, ...toBeAdded])
    } else {
      const toBeRemoved: string[] = []
      collectionVersions.forEach((ver) => {
        if (selectedVersionIds.includes(ver.getId())) toBeRemoved.push(ver.getId())
      })
      onSelect(selectedVersionIds.filter((id) => !toBeRemoved.includes(id)))
    }
  }
  return (
    <Box
      layerStyle='outline.interactive'
      my={3}
      borderRadius={'4'}
      className={'publisher-collection'}
      border={0}
      outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
      outlineColor={
        selectedSome || selectedAll ? 'var(--chakra-colors-primary-500)' : 'var(--chakra-colors-blackAlpha-200)'
      }
      _hover={
        selectedSome || selectedAll
          ? { background: 'white', outlineColor: 'var(--chakra-colors-primary-500)' }
          : isExpanded
          ? { background: 'white', outlineColor: 'var(--chakra-colors-blackAlpha-400)' }
          : { background: 'blackAlpha.100', outlineColor: 'var(--chakra-colors-blackAlpha-400)' }
      }
    >
      <AccordionButton padding={'6'} _hover={{ background: 'transparent' }} _expanded={{ paddingBottom: '3' }} as='div'>
        <Flex flex='1' textAlign='left' margin={3}>
          <Checkbox
            colorScheme={'primary'}
            isIndeterminate={selectedSome}
            isChecked={selectedAll}
            onChange={handleCheck}
          >
            <Flex fontSize={'md'} fontWeight={500}>
              <Text color='gray.800'>Collection: {collection.name} </Text>
              <Text ml={2} color='primary.500'>
                ({selectedNumber} out of {collectionVersions?.length} versions selected)
              </Text>
            </Flex>
          </Checkbox>
        </Flex>

        <IconButton
          icon={
            <Icon boxSize={'icon-lg'}>
              <path d={isExpanded ? mdiChevronUp : mdiChevronDown} />
            </Icon>
          }
          aria-label={'Expand Collection'}
        ></IconButton>
      </AccordionButton>
      <AccordionPanel pb={3} pt={0}>
        <Box px={3}>
          <Accordion reduceMotion allowMultiple>
            {components.map((component) => (
              <AccordionItem
                key={component.id}
                css={css`
                  border: 0;
                  background: white;
                `}
                borderRadius={3}
              >
                {({ isExpanded }: { isExpanded: boolean }) => (
                  <PublisherComponentsComponent
                    versions={versions}
                    component={component}
                    targetStatus={targetStatus}
                    selectedVersionIds={selectedVersionIds}
                    onSelect={onSelect}
                    isExpanded={isExpanded}
                  />
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </AccordionPanel>
    </Box>
  )
}

export default PublisherComponentsCollection

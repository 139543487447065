import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@chakra-ui/react'
import { FC, useContext, useEffect, useState } from 'react'
import { useData, useLibrary, useSDK } from '../hooks/useData.js'
import { EnvironmentContext, getEnvironment } from './providers/EnvironmentProvider.js'
import { SDKContext } from './providers/SDKProvider.js'

var loadAppSwitcher: Promise<any>
export const AppSwitcher: FC = () => {
  const { getAccessTokenSilently: getAccessToken, loginWithRedirect, user, tenant } = useSDK('auth')
  const library = useLibrary()
  const env = useContext(EnvironmentContext)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    loadAppSwitcher ??= import(
      /* @vite-ignore */
      'https://sitecoredelivr.sitecorecloud.io/npm/@sitecore-ui/web-components@latest/dist/sitecore-web-components/' +
        'sitecore-web-components.esm.js'
    ).catch(() => {})
  }, [])

  // Init app switcher
  useEffect(() => {
    const initialize = () => {
      const appSwitcher = document.querySelector('app-switcher')
      if (!appSwitcher || getEnvironment() == 'localhost' || getEnvironment() == 'ci') return

      // @ts-ignore
      appSwitcher?.componentOnReady?.().then(() => {
        appSwitcher
          // @ts-ignore
          .init({
            getAccessToken,
            loginWithRedirect,
            environment: env.inventoryEnv,
            organizationId: tenant.organizationId ?? '',
            tenantId: tenant.id,
            systemId: tenant.systemId
          })
          .then(() => {
            setReady(true)
          })
      })
    }
    Promise.resolve(loadAppSwitcher).then(() => {
      initialize()
    })
  }, [loginWithRedirect, getAccessToken, user, tenant])

  return (
    <Box zIndex={1} boxSize={'32px'} opacity={ready ? 1 : 0} transition='opacity 0.2s'>
      <app-switcher></app-switcher>
    </Box>
  )
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'app-switcher': any
    }
  }
}
